export function ClosedRingSVG() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FFF1F1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.52827 8.47108C7.26792 8.21073 7.26792 7.78862 7.52827 7.52827C7.78862 7.26792 8.21073 7.26792 8.47108 7.52827L11.9997 11.0569L15.5283 7.52827C15.7886 7.26792 16.2107 7.26792 16.4711 7.52827C16.7314 7.78862 16.7314 8.21073 16.4711 8.47108L12.9425 11.9997L16.4711 15.5283C16.7314 15.7886 16.7314 16.2107 16.4711 16.4711C16.2107 16.7314 15.7886 16.7314 15.5283 16.4711L11.9997 12.9425L8.47108 16.4711C8.21073 16.7314 7.78862 16.7314 7.52827 16.4711C7.26792 16.2107 7.26792 15.7886 7.52827 15.5283L11.0569 11.9997L7.52827 8.47108Z"
        fill="#EA0000"
      />
    </svg>
  );
}
