import { Dispatch, SetStateAction } from "react";
import { Typography } from "@mui/material";
import { ExitButton } from "@/src/component/base";
import { useBreakPointDown } from "@/src/hook";
import styles from "./DrawerFAQContainer.module.scss";

interface DrawerFAQContainerProps {
  setIsFAQMobileOpen: Dispatch<SetStateAction<boolean>>;
}

function DrawerFAQContainer({ setIsFAQMobileOpen }: DrawerFAQContainerProps) {
  const isMobile = useBreakPointDown("md");

  const onFAQSectionHandlerClose = () => {
    setIsFAQMobileOpen(false);
  };
  return (
    <div className={styles.faqWrapper}>
      {isMobile ? (
        <ExitButton
          onExit={onFAQSectionHandlerClose}
          className={styles.buttonExit}
        />
      ) : null}
      <Typography variant="h3">
        FAQ
      </Typography>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
        consequatur perferendis doloribus iure et omnis asperiores veniam vel
        cum laudantium. Lorem ipsum dolor sit amet consectetur adipisicing elit.
        Sit quo, deserunt soluta est in hic commodi dignissimos quidem
        voluptatum veniam, ea quaerat molestiae? Eveniet libero natus molestias
        nam explicabo iste, perspiciatis asperiores vero atque nisi? Adipisci
        debitis molestias, tempore incidunt vero repudiandae, nostrum delectus
        quisquam, assumenda laboriosam perferendis facilis harum? Lorem ipsum
        dolor sit amet consectetur, adipisicing elit. Possimus, odio enim.
        Adipisci perspiciatis vitae molestiae, ratione porro corporis, tempore
        cupiditate laudantium asperiores magnam quidem, commodi atque id veniam
        similique! Cumque doloribus quam vitae vero similique autem incidunt
        quod aliquam cum! Veniam mollitia quisquam eveniet dignissimos alias
        omnis delectus natus obcaecati debitis illo? Maiores quis est officia
        maxime porro pariatur necessitatibus assumenda. Asperiores quod
        doloribus quos. At, quod sapiente. Hic sequi neque ipsam repellat
        consequuntur quis facilis iusto dicta excepturi nobis voluptas nam omnis
        tempora temporibus debitis voluptate deserunt, eveniet voluptatem non
        laboriosam commodi dolor eaque repellendus. Dignissimos soluta,
        laudantium quibusdam minima eos dolore sunt voluptatibus odit commodi
        praesentium. Repudiandae, autem quisquam iste minima maiores assumenda
        eveniet officiis explicabo voluptate molestias dolor incidunt dolorem
        enim fugiat quaerat soluta ab necessitatibus voluptas unde inventore.
        Atque, sapiente aliquam. Incidunt hic libero, quod natus delectus nemo
        eius consectetur rerum officia voluptatem eligendi commodi ut quam
        sapiente quaerat consequuntur veritatis dicta quae vel, corporis saepe
        accusantium quisquam similique est. Consectetur ab tempora itaque.
        Pariatur explicabo reprehenderit ad magni sequi obcaecati earum libero
        distinctio, itaque hic eligendi esse, beatae recusandae atque nisi a?
        Voluptatum nesciunt nostrum quas optio veniam dolore, ad quam ullam vero
        officiis ratione illo suscipit quo inventore cumque, dicta doloremque.
        Vel totam, ex sit molestiae repellendus quisquam unde sint at amet,
        quidem et velit, ipsam placeat expedita dolores. Minima placeat, error
        debitis sequi architecto vero? Dignissimos blanditiis in, fugiat minus
        fuga soluta inventore autem, fugit ipsam, pariatur esse a. Blanditiis a
        vel ipsam illum quisquam optio, error libero, quae quidem cupiditate
        nemo quaerat officia fugiat id alias voluptatem repudiandae beatae
        mollitia debitis soluta, iure ipsa quis perferendis! Necessitatibus et
        expedita facilis molestiae enim aut aliquam odio quasi quo incidunt?
        Velit reiciendis, ipsam maxime corrupti odit facilis reprehenderit
        soluta qui aliquid fuga, harum exercitationem! Lorem ipsum dolor, sit
        amet consectetur adipisicing elit. Sed nesciunt ad exercitationem
        adipisci, assumenda laborum minus inventore id quos quibusdam.
      </p>
    </div>
  );
}

export default DrawerFAQContainer;
