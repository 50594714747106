import { decode } from "html-entities";
import { StringHelper } from "@/src/util";
import {
  DetailSectionInfo,
  DetailSectionInfoBody,
} from "@src/component/partial";
import styles from "./VenueThingsYouShouldKnow.module.scss";

interface VenueThingsYouShouldKnowProps {
  thingsYouShouldKnow?: string;
}

function VenueThingsYouShouldKnow({
  thingsYouShouldKnow,
}: VenueThingsYouShouldKnowProps) {
  if (!StringHelper.hasData(thingsYouShouldKnow)) {
    return <></>;
  }

  function parse(thingsYouShouldKnow: string): string[] {
    const arr = thingsYouShouldKnow.split("- ") ?? [];
    if (!StringHelper.hasData(arr[0])) {
      arr.splice(0, 1);
    }
    return arr;
  }

  if ((thingsYouShouldKnow ?? "").includes("- ")) {
    return (
      <DetailSectionInfo
        title="Things you should know"
        Component={
          <ul className={styles.venueThingsYouShouldKnowList}>
            {parse(thingsYouShouldKnow ?? "").map((opt, i) => (
              <li key={i}>
                <DetailSectionInfoBody key={i} tag="body1">
                  {decode(opt)}
                </DetailSectionInfoBody>
              </li>
            ))}
          </ul>
        }
      />
    );
  }

  return (
    <DetailSectionInfo
      title="Things you should know"
      Component={
        <DetailSectionInfoBody tag="body1">
          {thingsYouShouldKnow}
        </DetailSectionInfoBody>
      }
    />
  );
}

export default VenueThingsYouShouldKnow;
