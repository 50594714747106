import { Dispatch, memo, SetStateAction, useMemo } from "react";
import { DatePicker } from "@/src/component/base";
import styles from "./EventDateFilter.module.scss";

interface EventDateFilter {
  startDateValue: Date | null;
  endDateValue: Date | null;
  setStartDateValue: Dispatch<SetStateAction<Date | null>>;
  setEndDateValue: Dispatch<SetStateAction<Date | null>>;
}

function EventDateFilter({
  startDateValue,
  endDateValue,
  setStartDateValue,
  setEndDateValue,
}: EventDateFilter) {
  const customStyles = useMemo(() => {
    //todo - create and use theme variables for all colours
    return {
      "& .MuiInputBase-input": {
        backgroundColor: "#F5F7F9",
      },
      "& .MuiInputBase-root MuiOutlinedInput-root": {
        backgroundColor: "#F5F7F9",
      },
    };
  }, []);

  const maxDateCommon = new Date().setFullYear(new Date().getFullYear() + 2);

  const startDateInitialValue = startDateValue
    ? new Date(startDateValue)
    : null;

  const endDateInitialValue = endDateValue ? new Date(endDateValue) : null;

  return (
    <div className={styles.dataPickerWrapper}>
      <DatePicker
        slotProps={{
          textField: {
            placeholder: "Start Date",
            sx: customStyles,
          },
        }}
        className={styles.filterDataPicker}
        label="Start Date"
        views={["year", "month", "day"]}
        yearsPerRow={3}
        //@ts-ignore
        maxDate={maxDateCommon}
        value={startDateInitialValue}
        onChange={val => {
          setStartDateValue(val);
        }}
      />
      <DatePicker
        slotProps={{
          textField: {
            placeholder: "End Date",
            sx: customStyles,
          },
        }}
        className={styles.filterDataPicker}
        label="End Date"
        views={["year", "month", "day"]}
        yearsPerRow={3}
        //@ts-ignore
        maxDate={maxDateCommon}
        value={endDateInitialValue}
        onChange={val => {
          setEndDateValue(val);
        }}
      />
    </div>
  );
}

export default memo(EventDateFilter);
