import { Box, Typography } from "@mui/material";
import Image from "next/legacy/image";
import ErrorIcon from "@/public/icons/checkRing.svg";
import styles from "./Error.module.scss";

function Error() {
  return (
    <Box className={styles.errorWrapper}>
      <Image src={ErrorIcon} alt="Error icon" />
      <Typography className={styles.errorText}>
        We faced internal server error.
      </Typography>
      <Typography className={styles.errorText}>
        Do not worry. We are working on it. Just reload the page and try again.
      </Typography>
    </Box>
  );
}

export default Error;
