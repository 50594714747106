import React from "react";
import { Error } from "./Error";
import { Fetching } from "./Fetching";
import { LogEmpty } from "./LogEmpty";

interface ChatTabFallbackProps {
  isFetching: boolean;
  isLogEmpty: boolean;
  isError: boolean;
}

function ChatTabFallback({
  isFetching,
  isLogEmpty,
  isError,
}: ChatTabFallbackProps) {
  if (isFetching) {
    return <Fetching />;
  }

  if (isLogEmpty) {
    return <LogEmpty />;
  }

  if (isError) {
    return <Error />;
  }

  return null;
}

export default ChatTabFallback;
