import { CloseInquiryOptions } from "@/src/type/blaceV2";

export const INQUIRY_CLOSED_REASON_EXCHANGE: Record<CloseInquiryOptions,string> = {
  [CloseInquiryOptions.ANOTHER_BUSINESS]: "Went with a Different Venue",
  [CloseInquiryOptions.BUDGET_LOW]: "Unqualified Budget",
  [CloseInquiryOptions.NOT_AVAILABLE_DATES]: "Date Unavailable",
  [CloseInquiryOptions.NO_AMENITY]: "No Venues that Match",
  [CloseInquiryOptions.NO_EVENT]: "Event Cancelled",
  [CloseInquiryOptions.OTHER]: "Other",
  [CloseInquiryOptions.UNRESPONSIVE_BUSINESS]: "Unresponsive",
};
