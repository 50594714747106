import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import Image from "next/legacy/image";
import NoEventsIcon from "@/public/icons/noEventsIcon.svg";
import { BaseButton } from "@/src/component/base";
import styles from "./BaseFallbackPopup.module.scss";

interface BaseFallbackPopupProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  ctaText?: string;
  popupClasses?: string;
  isClosable?: boolean;
  handleCtaClick?: () => void;
  handleClose?: () => void;
}

function BaseFallbackPopup({
  isOpen,
  title,
  subTitle,
  ctaText,
  popupClasses,
  isClosable = false,
  handleCtaClick,
  handleClose,
}: BaseFallbackPopupProps) {
  return (
    isOpen && (
      <Dialog
        open={isOpen}
        className={classNames(styles.popupDialog, popupClasses)}
        onClose={handleClose}
      >
        {isClosable && (
          <div className={styles.closeBtnContainer}>
            <div className={styles.closeBtnContainerInner}>
              <IconButton
                color="inherit"
                onClick={handleClose}
                data-testid="close-button"
              >
                <CloseIcon color="inherit" />
              </IconButton>
            </div>
          </div>
        )}
        <div className={styles.iconWrapper}>
          <Image src={NoEventsIcon} alt="no events icon" />
        </div>
        <DialogContent>
          <Typography variant="h4" className={styles.popupTitle}>
            {title}
          </Typography>
          <Typography className={styles.popupSubTitle}>{subTitle}</Typography>
          <Divider />
        </DialogContent>
        {ctaText && handleCtaClick && (
          <BaseButton
            onClick={handleCtaClick}
            color="primary"
            className={styles.popupCTAButton}
            data-testid="fallback-cta"
          >
            {ctaText}
          </BaseButton>
        )}
      </Dialog>
    )
  );
}

export default BaseFallbackPopup;
