import { SplitLayout } from "@/src/component/layout";
import { RequireAuthNoSSR, ResetPasswordForm } from "@/src/component/partial";

function ForgotPassword() {
  return (
    <SplitLayout>
      <RequireAuthNoSSR>
        <ResetPasswordForm />
      </RequireAuthNoSSR>
    </SplitLayout>
  );
}

export default ForgotPassword;
