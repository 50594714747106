import { useEffect, useState } from "react";
import { decode } from "html-entities";
import sanitizeHtml from "sanitize-html";
import { PRICE_STARTING_PRE_TEXT } from "@/src/const";
import { VenueLogic } from "@/src/model";
import { SearchType } from "@/src/type/blaceV2";
import { SanitizeHelper, StringHelper } from "@/src/util";
import {
  DetailSectionInfo,
  DetailSectionInfoBody,
} from "@src/component/partial";
import styles from "./VenuePrice.module.scss";

interface VenuePriceProps {
  venue: SearchType.SearchItem;
}

function VenuePrice({ venue }: VenuePriceProps) {
  const [dangerous, setDangerous] = useState<string>("");

  useEffect(() => {
    const prepared =
      SanitizeHelper.noLinks(
        sanitizeHtml(decode(VenueLogic.getPricingDetails(venue))),
        " the inquiry form"
      ) ?? "";
    const removeBreaks = prepared
      .replaceAll("<br />", "")
      .replaceAll("<br>", "");
    const removeStrong = removeBreaks.replaceAll("strong", "p");
    setDangerous(removeStrong);
  }, [venue]);

  const hasDetails = StringHelper.hasData(venue?.price?.details);
  const displayingPrice = VenueLogic.formatVenuePricing(venue, PRICE_STARTING_PRE_TEXT);

  return (
    <DetailSectionInfo
      title={hasDetails ? "Pricing Details" : "Pricing"}
      Component={
        <div className={hasDetails ? styles.vendorPriceContainer : undefined}>
          {!hasDetails && (
            <DetailSectionInfoBody tag="body1">
              {displayingPrice}
            </DetailSectionInfoBody>
          )}
          {hasDetails && (
            <div className={styles.vendorPriceContent}>
              <DetailSectionInfoBody tag="body1" elementType={"div"}>
                <div
                  suppressHydrationWarning={true}
                  dangerouslySetInnerHTML={{
                    __html: `${dangerous}`,
                  }}
                />
              </DetailSectionInfoBody>
            </div>
          )}
        </div>
      }
    />
  );
}

export default VenuePrice;
