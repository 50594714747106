import { SplitLayout } from "@/src/component/layout";
import { SignInForm } from "@/src/component/partial";

function SignIn() {
  return (
    <SplitLayout>
      <SignInForm />
    </SplitLayout>
  );
}

export default SignIn;
