import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import cn from "classnames";
import {
  DetailSectionInfo,
  DetailSectionInfoBody,
  Map,
} from "@/src/component/partial";
import { VenueLogic } from "@/src/model";
import { SearchType } from "@/src/type/blaceV2";
import styles from "./VenueMap.module.scss";

interface VenueMapProps {
  venue: SearchType.SearchItem;
}

type HandleImperativeVenueMapRefType = {
  openMapFullScreen: () => void;
};

const VenueMapComponent: ForwardRefRenderFunction<
  HandleImperativeVenueMapRefType,
  VenueMapProps
> = ({ venue }: VenueMapProps, ref) => {
  const [mapFullScreen, setMapFullScreen] = useState<boolean>(false);
  const venueLocation = venue.locations.length ? venue.locations[0] : undefined;

  /**
   * this disables the background from scrolling while te map is open
   * //TODO - move tis into a hook for re-use
   */
  useEffect(() => {
    if (mapFullScreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.removeAttribute("style");
    }
  }, [mapFullScreen]);

  /**
   * This allows the parent component to open the map
   * Alternative: this could be orchestrate with state from the parent being passed to child
   */
  useImperativeHandle(
    ref,
    (): HandleImperativeVenueMapRefType => ({
      openMapFullScreen: () => setMapFullScreen(true),
    })
  );

  if (!venueLocation?.latitude || !venueLocation?.longitude) {
    return <></>;
  }

  const venueMarker = {
    lat: venueLocation?.latitude ?? 0,
    lng: venueLocation.longitude ?? 0,
  };

  return (
    <DetailSectionInfo
      title={"Location"}
      className={styles.detailMapSectionInfo}
      Component={
        <>
          <DetailSectionInfoBody tag="h3">
            {VenueLogic.getAddressForDisplay(venue, true)}
          </DetailSectionInfoBody>
          <div
            className={cn(
              styles.detailMap,
              mapFullScreen ? styles.detailMapFullScreen : undefined
            )}
            onClick={mapFullScreen ? undefined : () => setMapFullScreen(true)}
          >
            <Map
              visible={true}
              markers={[venueMarker]}
              centerMarker={venueMarker}
              defaultZoom={15}
              additionalOptions={{
                keyboardShortcuts: false,
                zoomControl: mapFullScreen,
                gestureHandling: mapFullScreen ? "cooperative" : "none",
              }}
              handleMapToggle={
                mapFullScreen ? () => setMapFullScreen(false) : undefined
              }
            />
          </div>
        </>
      }
    />
  );
};

export const VenueMap = forwardRef(VenueMapComponent);
