import { Dispatch, memo, SetStateAction } from "react";
import { Typography } from "@mui/material";
import Image from "next/image";
import ErrorIcon from "@/public/icons/searchError.svg";
import NoSearchMatchesIcon from "@/public/icons/searchResult.svg";
import { BaseButton } from "@/src/component/base";
import { InquiryFiltersType } from "@/src/type/blaceV2/BlaceV2Type";
import styles from "./SearchFiltersFallback.module.scss";

interface SearchFiltersFallbackProps {
  isNoSearchMatchesInquiry: boolean;
  isNoFiltersMatchesInquiry: boolean;
  isErrorBySearch: boolean;
  isErrorByFiltering: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  setSearchValue: (value: string) => void;
  setAppliedFilters: Dispatch<SetStateAction<InquiryFiltersType | undefined>>;
  setIsURLWithQueryParams: Dispatch<SetStateAction<boolean>>;
}

function SearchFiltersFallback({
  isNoSearchMatchesInquiry,
  isNoFiltersMatchesInquiry,
  isErrorBySearch,
  isErrorByFiltering,
  setIsFetching,
  setSearchValue,
  setAppliedFilters,
  setIsURLWithQueryParams,
}: SearchFiltersFallbackProps) {
  const onClearSearchHandler = () => {
    setSearchValue("");
    setIsFetching(true);
  };

  const onClearFiltersHandler = () => {
    setAppliedFilters(undefined);
    setIsURLWithQueryParams(false);
    setIsFetching(true);
  };

  if (isNoSearchMatchesInquiry && isNoFiltersMatchesInquiry) {
    return (
      <div className={styles.noMatchesWrapper}>
        <Image src={NoSearchMatchesIcon} alt="No matches icon" />
        <Typography className={styles.title}>No inquires found</Typography>
        <Typography className={styles.description}>
          Your search and filters did not match any inquires. Please, try again
        </Typography>
        <BaseButton
          className={styles.clearButton}
          onClick={() => {
            onClearSearchHandler();
            onClearFiltersHandler();
          }}
        >
          Clear Search and Filters
        </BaseButton>
      </div>
    );
  }

  if (isNoSearchMatchesInquiry || isNoFiltersMatchesInquiry) {
    return (
      <div className={styles.noMatchesWrapper}>
        <Image src={NoSearchMatchesIcon} alt="No matches icon" />
        <Typography className={styles.title}>No inquires found</Typography>
        <Typography className={styles.description}>{` Your ${
          isNoSearchMatchesInquiry ? "search" : "filters"
        } did not match any inquires. Please try again.`}</Typography>
        <BaseButton
          className={styles.clearButton}
          onClick={
            isNoSearchMatchesInquiry
              ? onClearSearchHandler
              : onClearFiltersHandler
          }
        >
          {`Clear ${isNoSearchMatchesInquiry ? "Search" : "Filters"}`}
        </BaseButton>
      </div>
    );
  }

  if (isErrorBySearch || isErrorByFiltering) {
    return (
      <div className={styles.errorWrapper}>
        <Image src={ErrorIcon} alt="Error icon" className={styles.errorIcon} />
        <Typography className={styles.description}>
          We faced internal server error
        </Typography>
        <Typography className={styles.description}>
          Do not worry. We are working on it. Just reload the page and try again
        </Typography>
      </div>
    );
  }
}

export default memo(SearchFiltersFallback);
