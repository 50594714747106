import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useBreakPointDown } from "@/src/hook";
import { InquiryLogLogic } from "@/src/model";
import { InquiryServiceV2 } from "@/src/service/BlaceV2";
import { InquiryLog, InquiryLogEventTypes, InquiryLogSourceTypes, InquirySearchType, InquiryStatusLabels } from "@/src/type/blaceV2";
import { Log } from "@/src/util";
import styles from "./ChatTabContent.module.scss";
import { ChatTabFallback } from "./component/ChatTabFallback";
import { LogListItem } from "./component/LogListItem";

function getDefaultLogs(inquiry: InquirySearchType): InquiryLog[] {
  return [
    {
      createDate: inquiry.createDate,
      updateDate: inquiry.updateDate,
      userId: "some-id-1",
      inquiryLogId: "some-id-2",
      inquiryId: inquiry.inquiryId,
      sourceType: InquiryLogSourceTypes.Client,
      eventType: InquiryLogEventTypes.Submit,
      eventTypeId: "some-id-3",
      shortProperties: {
        notes: inquiry.notes,
      },
      fullProperties: {},
    },
  ];
}

interface ChatTabContentProps {
  selectedInquiry: InquirySearchType | null;
}

function ChatTabContent({ selectedInquiry }: ChatTabContentProps) {
  const [logDataList, setLogDataList] = useState<InquiryLog[] | null>(null);
  const [isError, setIsError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const chatContainerRef = useRef<HTMLUListElement | null>(null);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLogEmpty = !Boolean(logDataList);
  const isFallbackVisible = isFetching || isLogEmpty || isError;
  const isMobile = useBreakPointDown("md");

  const inquiryMessagesList = useMemo(() => {
    if (logDataList) {
      return InquiryLogLogic.groupMessagesByDay(logDataList);
    }
    return null;
  }, [logDataList]);

  const chatTabClasses = useMemo(() => {
    return cn(styles.chatTabWrapper, {
      [styles.withClosedHeader]:
        selectedInquiry?.inquiryStatus === InquiryStatusLabels.Closed,
    });
  }, [logDataList]);

  const scrollToBottom = () => {
    if (chatContainerRef.current && logDataList) {
      const chatEnd = chatEndRef.current?.getBoundingClientRect();
      chatEnd &&
        chatContainerRef.current?.scrollTo({
          left: 0,
          top: chatEnd?.top,
          behavior: "smooth",
        });
      if (isMobile && chatEnd) {
        const footerHeight = 750;
        setTimeout(() => {
          window.scrollTo({
            left: 0,
            top: chatEnd?.top - footerHeight,
            behavior: "smooth",
          });
        }, 100);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [logDataList]);

  useEffect(() => {
    const fetchActivityLogData = async () => {
      setIsFetching(true);
      if (!selectedInquiry?.inquiryId) {
        setIsFetching(false);
        return;
      }
      try {
        const responseData = await InquiryServiceV2.getInquiryLogs(selectedInquiry?.inquiryId);
        if (responseData.body?.metadata?.statusCode === 200) {
          const { results } = responseData.body.payload;
          setLogDataList(results);
        } else {
          const defaultLogs = getDefaultLogs(selectedInquiry);
          setLogDataList(defaultLogs);
        }
        setIsFetching(false);
      } catch (error) {
        Log.logToDataDog(Log.LogLevel.ERROR, "ChatTabContent.tsx", "ChatLogError", [error]);
        setIsFetching(false);
        setIsError(true);
      }
    };

    fetchActivityLogData();
  }, [selectedInquiry?.inquiryId]);

  if (isFallbackVisible) {
    return <ChatTabFallback isFetching={isFetching} isLogEmpty={isLogEmpty} isError={isError} />;
  }

  return (
    <Box className={chatTabClasses} ref={chatContainerRef}>
      <ul className={styles.inquiryMessagesList}>
        {inquiryMessagesList?.map((inquiryMessages, index) => {
          return (
            <li key={index}>
              <LogListItem inquiryMessages={inquiryMessages} />
            </li>
          );
        })}
      </ul>
      <div ref={chatEndRef} />
    </Box>
  );
}

export default ChatTabContent;
