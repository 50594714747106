import { SplitLayout } from "@/src/component/layout";
import { ForgotPasswordForm } from "@/src/component/partial";

function ForgotPassword() {
  return (
    <SplitLayout>
      <ForgotPasswordForm />
    </SplitLayout>
  );
}

export default ForgotPassword;
