import { SplitLayout } from "@/src/component/layout";
import { SingUpForm } from "@/src/component/partial";

function SignUp() {
  return (
    <SplitLayout>
      <SingUpForm />
    </SplitLayout>
  );
}

export default SignUp;
