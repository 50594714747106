import { ChangeEvent } from "react";
import { Pagination } from "@mui/material";
import { useBreakPointBetween, useBreakPointDown } from "@/src/hook/style";
import styles from "./Pagination.module.scss";

interface PaginationProps {
  paginationPagesAmount: number;
  paginationCurrentPage: number;
  isPaginationHidden: boolean;
  responsiveSize?: boolean;
  handleNewPage: (step: number) => void;
}

function PaginationComponent({
  paginationPagesAmount,
  paginationCurrentPage,
  isPaginationHidden,
  responsiveSize = false,
  handleNewPage,
}: PaginationProps) {
  const isMobile = useBreakPointDown("md");
  const isMediumWidth = useBreakPointBetween(["md", "lg"]);

  const handlePaginationChange = (
    event: ChangeEvent<unknown>,
    value: number
  ) => {
    handleNewPage(value);

    isMobile && window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
  };

  if (paginationPagesAmount < 2 || isPaginationHidden) {
    return null;
  }

  return (
    <div className={styles.paginationWrapper}>
      <Pagination
        count={paginationPagesAmount}
        page={paginationCurrentPage}
        color="standard"
        shape="rounded"
        siblingCount={1}
        boundaryCount={0}
        size={isMediumWidth && responsiveSize ? "small" : "medium"}
        onChange={handlePaginationChange}
      />
    </div>
  );
}

export default PaginationComponent;
