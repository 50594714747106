import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import { CMSLogic } from "@/src/model";
import { StrapiCMSType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { BaseButton } from "@src/component/base";
import styles from "./VenueButtonBar.module.scss";

/* prettier-ignore */
interface VenueButtonBarProps {
  venue: SearchType.SearchItem;
  venueMarketingAsset?: StrapiCMSType.StrapiData<
    StrapiCMSType.VenueMarketingAssetType & StrapiCMSType.StrapiAttributes<StrapiCMSType.VenueMarketingAssetType>
  >;
  openMapButton: () => void;
}

function VenueButtonBar({
  venue,
  venueMarketingAsset,
  openMapButton,
}: VenueButtonBarProps) {
  //TODO: remove this logic when other buttons have data that can be used
  const venueLocation = venue.locations.length ? venue.locations[0] : undefined;
  if (!venueLocation?.latitude || !venueLocation?.longitude) {
    return <></>;
  }

  function getTearSheetURL() {
    /* prettier-ignore */
    const url = venueMarketingAsset?.attributes?.venueDetail?.tearSheet?.data?.attributes?.url;
    if (typeof url === "string") {
      return CMSLogic.replaceStorageUrlWithCdnUrl(url);
    }
  }

  function openTearSheat() {
    window.open(getTearSheetURL(), "_blank");
  }

  function getFloorPlanURL() {
    /* prettier-ignore */
    const url = venueMarketingAsset?.attributes?.venueDetail?.floorPlan?.data?.attributes?.url;
    if (typeof url === "string") {
      return CMSLogic.replaceStorageUrlWithCdnUrl(url);
    }
  }

  function openFloorPlan() {
    window.open(getFloorPlanURL(), "_blank");
  }

  return (
    <div className={styles.detailSpecButtonBar}>
      {venueLocation?.latitude && venueLocation?.longitude && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openMapButton}
        >
          <MapOutlinedIcon />
          Show Map
        </BaseButton>
      )}
      {typeof getTearSheetURL() === "string" && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openTearSheat}
        >
          <ViewKanbanOutlinedIcon />
          Tear Sheet
        </BaseButton>
      )}
      {typeof getFloorPlanURL() === "string" && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openFloorPlan}
        >
          <ViewQuiltOutlinedIcon />
          Floor Plan
        </BaseButton>
      )}
      {/*
      <BaseButton
        type="submit"
        variant="outlined"
        size="large"
      >
        <ViewInArOutlinedIcon />
        3D Tour
      </BaseButton>
    */}
    </div>
  );
}

export default VenueButtonBar;
