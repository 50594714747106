import React from "react";
import { Box, Typography } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { InquiryLog, InquiryLogSourceTypes } from "@/src/type/blaceV2";
import styles from "./ClosedLogMessage.module.scss";

interface ClosedMessageProps {
  message: InquiryLog;
}

function ClosedMessage({ message }: ClosedMessageProps) {
  const isFromClient = message.sourceType === InquiryLogSourceTypes.Client;
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper} data-testid="closed-message">
        <Typography className={styles.contentTitle}>
          {isFromClient ? "Initiated by client" : "Initiated by business"}
        </Typography>
        <Typography className={styles.contentText}>
          {message?.shortProperties?.fullReason ??
            "The inquiry has been closed"}
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default ClosedMessage;
