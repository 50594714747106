import { useEffect } from "react";
import { SplitLayout } from "@/src/component/layout";
import { PageLoading } from "@/src/component/partial";
import { useInquiryCallback } from "@/src/hook";
import { BlaceV2Type } from "@/src/type";
import { Log } from "@/src/util";

interface InquiryCallbackProps {
  inquiry: BlaceV2Type.EventType.Inquiry;
}

function InquiryCallback({ inquiry }: InquiryCallbackProps) {
  const inquiryCallback = useInquiryCallback();

  useEffect(() => {
    Log.logToConsoleDebug(
      "InquiryCallback",
      "Complete inquiry callback with authorization",
      [inquiry]
    );
    inquiryCallback.complete(inquiry);
  }, [inquiry]);

  return (
    <SplitLayout>
      <PageLoading />
    </SplitLayout>
  );
}

export default InquiryCallback;
