import { memo, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";
import { Event } from "@/src/type/blaceV2/BlaceV2Type";
import styles from "./EventFilter.module.scss";

interface EventFilterProps {
  eventValue: string | null;
  eventsList: Event[];
  selectEventCloseHandler: () => void;
  selectEventOpenHandler: () => void;
  eventChangeHandler: (value: string | null) => void;
}

function EventFilter({
  eventValue,
  eventsList,
  selectEventCloseHandler,
  selectEventOpenHandler,
  eventChangeHandler,
}: EventFilterProps) {
  const textInputStyles = useMemo(() => {
    if (eventsList.length) {
      const commonStyles = {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "16px",
        color: "#667593",
        zIndex: "9999",
      };
      return {
        fieldset: {
          backgroundColor: "#F5F7F9",
        },
        input: {
          ...commonStyles,
          backgroundColor: "#F5F7F9 !important",
          zIndex: "9998",

          "&:focus": {
            zIndex: "999",
          },
        },
        label: commonStyles,
        "& .MuiInputLabel-root.Mui-focused": {
          zIndex: "999",
        },
      };
    }
  }, [eventsList.length]);

  if (!eventsList.length) {
    return null;
  }
  const selectedValue = eventValue ? eventValue : "";

  const options = eventsList.map((event) => event.eventName);

  return (
    <Autocomplete
      disablePortal
      id="event-select"
      options={options}
      onOpen={selectEventOpenHandler}
      onClose={selectEventCloseHandler}
      defaultValue="defaultOption"
      value={selectedValue}
      data-testid="event-select"
      onChange={(event: any, newValue: string | null) => {
        eventChangeHandler(newValue);
      }}
      className={styles.select}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={textInputStyles}
          label="Choose an event"
          variant="outlined"
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
    />
  );
}

export default memo(EventFilter);
