import { memo } from "react";
import { FilterDesktop } from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/FilterDesktop";
import { FilterMobile } from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/FilterMobile";

interface FilterResponsiveWrapperProps {
  isOpenFiltersDropDown: boolean;
  isDialogFiltersOpen: boolean;
  isDesktop: boolean;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  handleMobileDialogFiltersClose: () => void;
  handleClosePopperDropDown: () => void;
  children: React.ReactElement;
}

function FilterResponsiveWrapper({
  children,
  isDesktop,
  isOpenFiltersDropDown,
  isDialogFiltersOpen,
  handleClosePopperDropDown,
  handleListKeyDown,
  handleMobileDialogFiltersClose,
}: FilterResponsiveWrapperProps) {
  if (isDesktop) {
    return (
      <FilterDesktop
        isOpenFiltersDropDown={isOpenFiltersDropDown}
        handleClosePopperDropDown={handleClosePopperDropDown}
        handleListKeyDown={handleListKeyDown}
      >
        {children}
      </FilterDesktop>
    );
  }

  return (
    <FilterMobile
      isDialogFiltersOpen={isDialogFiltersOpen}
      handleDialogClose={handleMobileDialogFiltersClose}
    >
      {children}
    </FilterMobile>
  );
}

export default memo(FilterResponsiveWrapper);
