import { useCallback } from "react";
import { ListingSection } from "@/src/component/view/InquiryComplete/component/ListingSection";
import { SimilarSection } from "@/src/component/view/InquiryComplete/component/SimilarSection";
import { BlaceV2Type } from "@/src/type";
import { Search } from "@/src/type/blaceV2/search/SearchType";

interface CommonSearchProps {
  type: BlaceV2Type.InquiryTypes;
  itemsList: Search[] | null;
}
interface VendorVenueSectionProps {
  inquiryId?: string;
  commonSearch?: Array<CommonSearchProps> | [];
  sendIquiryHandler: (dataType: string, slug: string) => void;
  backgroundImageCity?: string;
}

function VendorVenueSection({
  commonSearch,
  inquiryId,
  sendIquiryHandler,
  backgroundImageCity,
}: VendorVenueSectionProps) {
  const getTitleText = useCallback((type: string) => {
    return type === BlaceV2Type.InquiryTypes.Venue
      ? "Similar Venues"
      : "Recommended Vendors";
  }, []);

  const getDescriptionText = useCallback((type: string) => {
    return type === BlaceV2Type.InquiryTypes.Venue
      ? "We recommend you contact 3 to 5 venues to find the perfect space"
      : "Add expert vendors to your event for a flawless production!";
  }, []);

  return (
    <>
      {commonSearch?.map(({ type, itemsList }) => (
        <div key={type}>
          <SimilarSection
            inquiryType={type}
            titleText={getTitleText(type)}
            descriptionText={getDescriptionText(type)}
            backgroundImageCity={backgroundImageCity}
          />
          {itemsList && (
            <ListingSection
              itemsList={itemsList}
              sendIquiryHandler={sendIquiryHandler}
              inquiryId={inquiryId}
            />
          )}
        </div>
      ))}
    </>
  );
}

export default VendorVenueSection;
