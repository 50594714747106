import { Box, CircularProgress } from "@mui/material";
import styles from "./Fetching.module.scss";

function Fetching() {
  return (
    <Box className={styles.progressWrapper}>
      <CircularProgress data-testid="progress" color="secondary" />
    </Box>
  );
}

export default Fetching;
