import { Error } from "./component/Error";
import { Fetching } from "./component/Fetching";
import { NoEvents } from "./component/NoEvents";

interface DashboardFallbackProps {
  isFetching: boolean;
  isNoCreatedIquiries: boolean;
  isError: boolean;
}

function DashboardFallback({
  isFetching,
  isNoCreatedIquiries,
  isError,
}: DashboardFallbackProps) {
  if (isFetching) {
    return <Fetching />;
  }

  if (isNoCreatedIquiries) {
    return <NoEvents />;
  }

  if (isError) {
    return <Error />;
  }

  return null;
}

export default DashboardFallback;
