import { useEffect } from "react";
import type { SyntheticEvent } from "react";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";
import ViewQuiltTwoToneIcon from "@mui/icons-material/ViewQuiltTwoTone";
import { Tab, Tabs } from "@mui/material";
import { VenueLogic } from "@/src/model";
import { SearchType } from "@/src/type/blaceV2";

interface VenueTabsProps {
  detailTabs: string;
  venue: SearchType.SearchItem;
  setInitialTab: (tab: string) => void;
  handleDetailTabChange: (event: SyntheticEvent, newValue: string) => void;
}

function VenueTabs({
  detailTabs,
  venue,
  handleDetailTabChange,
}: VenueTabsProps) {
  useEffect(() => {
    //nextNavigiation.updateQueryString(QueryParams.VENUE_TABS, detailTabs);
  }, [detailTabs]);

  useEffect(() => {
    //setInitialTab(toStartAt);
    //figure out how to set the default at start
    //this was causing an issue with MUI that create an re-render loop on the <Tabs /> component
  }, []);

  return (
    <Tabs
      value={detailTabs}
      onChange={handleDetailTabChange}
      indicatorColor="secondary"
      textColor="secondary"
    >
      <Tab
        label="About"
        value="about"
        icon={<InfoTwoToneIcon />}
        iconPosition="start"
      />
      {VenueLogic.getVenueRooms(venue).length > 0 && (
        <Tab
          label="Rooms"
          value="rooms"
          icon={<ViewQuiltTwoToneIcon />}
          iconPosition="start"
        />
      )}
      {venue.facts?.hasExclusiveVendor && ( // check for exclusive vendors
        <Tab
          label="Vendors"
          value="vendors"
          icon={<LocalMallTwoToneIcon />}
          iconPosition="start"
        />
      )}
    </Tabs>
  );
}

export default VenueTabs;
