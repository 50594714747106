import { useEffect } from "react";
import { SplitLayout } from "@/src/component/layout";
import { PageLoading } from "@/src/component/partial";
import { useNextNavigation } from "@/src/hook";
import { ActivityLogic, CMSLogic } from "@/src/model";

type DecodedParams = {
  captureContact: boolean;
  captureContactFields: string;
  linkId: string;
  redirect: string;
};

function Redirect() {
  const nextNavigation = useNextNavigation();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = searchParams.get("p");
    if (!params) {
      nextNavigation.router.push(CMSLogic.constructLink("/discover"));
      return;
    }

    try {
      //decode the link redirect
      const decodedParams: DecodedParams = JSON.parse(atob(params));
      if (!decodedParams.redirect.includes("https://")) {
        nextNavigation.router.push(CMSLogic.constructLink("/discover"));
        return;
      }

      //todo: add render contact capture option here
      if (decodedParams.captureContact) {
        //setShowContactCapture(true);
      }

      //delete the redirect object
      searchParams.delete("p");
      const redirect = `${decodedParams.redirect}${
        decodedParams.redirect.includes("?")
          ? `&${searchParams}`
          : `?${searchParams}`
      }`;

      //log the redirect in the activity log
      ActivityLogic.toActivityService({
        action: "redirect",
        actionId: decodedParams.linkId,
        actionIdType: "linkId",
        actionMessage: redirect,
      });

      window.open(redirect, "_self");
      return;
    } catch (err) {
      nextNavigation.router.push(CMSLogic.constructLink("/discover"));
      return;
    }
  }, []);

  return (
    <SplitLayout>
      <PageLoading />
    </SplitLayout>
  );
}

export default Redirect;
