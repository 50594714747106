import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Image from "next/image";
import RightIconCollapse from "@/public/icons/arrowRight.svg";
import { BaseButton } from "@/src/component/base";
import { BaseLoader } from "@/src/component/base/BaseLoader";
import { useBreakPointUp } from "@/src/hook";
import { ActivityLogic } from "@/src/model";
import {
  Event,
  InquiryFiltersType,
  InquirySearchType,
} from "@/src/type/blaceV2/BlaceV2Type";
import { AppliedFiltersNumberHelper, NumberHelper } from "@/src/util/helper";
import styles from "./LeftContainer.module.scss";
import { EventList } from "./components/EventList";
import { InputFilter } from "./components/Filters";
import { FilterResponsiveWrapper } from "./components/Filters/FilterResponsiveWrapper";
import { FiltersList } from "./components/Filters/FiltersList";
import { StatusFilters } from "./components/Filters/StatusFilter";
import { PaginationComponent } from "./components/Pagination";

interface LeftContainerProps {
  isLeftContainerCollapsed: boolean;
  isNoSearchMatchesInquiry: boolean;
  isNoFiltersMatchesInquiry: boolean;
  isFetching: boolean;
  isErrorBySearch: boolean;
  initialInquiryId: string;
  isErrorByFiltering: boolean;
  searchValue: string;
  paginationPagesAmount: number;
  paginationCurrentPage: number;
  totalInquiriesAmount: number;
  inquiriesList?: InquirySearchType[] | [];
  eventsList: Event[] | [];
  appliedFilters?: InquiryFiltersType;
  handleNewPage: (step: number) => void;
  setRequestInquiriesSkip: Dispatch<SetStateAction<number>>;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  setAppliedFilters: Dispatch<SetStateAction<InquiryFiltersType | undefined>>;
  setSelectedInquiry: Dispatch<SetStateAction<InquirySearchType | null>>;
  setIsURLWithQueryParams: Dispatch<SetStateAction<boolean>>;
  setIsLeftContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setSearchValue: (value: string) => void;
}

function LeftContainer({
  isLeftContainerCollapsed,
  isNoSearchMatchesInquiry,
  isNoFiltersMatchesInquiry,
  isFetching,
  isErrorBySearch,
  isErrorByFiltering,
  initialInquiryId,
  totalInquiriesAmount,
  inquiriesList,
  eventsList,
  searchValue,
  appliedFilters,
  paginationPagesAmount,
  paginationCurrentPage,
  setRequestInquiriesSkip,
  handleNewPage,
  setIsFetching,
  setSearchValue,
  setAppliedFilters,
  setSelectedInquiry,
  setIsURLWithQueryParams,
  setIsLeftContainerCollapsed,
}: LeftContainerProps) {
  const defaultStatusFilters: StatusFilters = {
    open: false,
    booked: false,
    completed: false,
    closed: false,
  };
  const [selectedInquiryId, setSelectedInquiryId] = useState<
    string | undefined
  >("");
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] =
    useState<boolean>(false);
  const [isOpenFiltersDropDown, setIsOpenFiltersDropDown] =
    useState<boolean>(false);
  const [statusFilters, setStatusFilters] = useState(defaultStatusFilters);
  const [startDateValue, setStartDateValue] = useState<Date | null>(null);
  const [endDateValue, setEndDateValue] = useState<Date | null>(null);
  const [eventValue, setEventValue] = useState<string | null>("");
  const [appliedFiltersAmount, setAppliedFiltersAmount] = useState<number>(0);
  const [isDialogFiltersOpen, setIsDialogFiltersOpen] =
    useState<boolean>(false);
  const [isEventSelectOpen, setIsEventSelectOpen] = useState(false);

  const isDesktop = useBreakPointUp("md");

  const isPaginationHidden =
    isNoSearchMatchesInquiry ||
    isNoFiltersMatchesInquiry ||
    isErrorBySearch ||
    isErrorByFiltering;

  const isEventListWithoutPagination = useMemo(() => {
    return isPaginationHidden || paginationPagesAmount < 2;
  }, [isPaginationHidden, paginationPagesAmount]);

  const selectEventCloseHandler = () => {
    setIsEventSelectOpen(false);
  };

  const selectEventOpenHandler = () => {
    setIsEventSelectOpen(true);
  };

  const clearFiltersHandler = useCallback(() => {
    const clearedStatusFilters = { ...defaultStatusFilters };
    setStatusFilters(clearedStatusFilters);
    setSearchValue("");
    setStartDateValue(null);
    setEndDateValue(null);
    setEventValue("");
    setAppliedFiltersAmount(0);
    setAppliedFilters(undefined);
    setIsOpenFiltersDropDown(false);
    setIsURLWithQueryParams(false);
    isDialogFiltersOpen && setIsDialogFiltersOpen(!isDialogFiltersOpen);
    appliedFiltersAmount && setIsFetching(true);
    setRequestInquiriesSkip(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogFiltersOpen, setAppliedFilters, appliedFiltersAmount]);

  const onClickCollapseHandler = () => {
    setIsLeftContainerCollapsed(!isLeftContainerCollapsed);
  };

  const onFocusInputHandler = () => {
    isOpenFiltersDropDown && setIsOpenFiltersDropDown(false);
  };

  const handleClosePopperDropDown = () => {
    !isEventSelectOpen && setIsOpenFiltersDropDown(false);
  };

  const handleMobileDialogFiltersClose = () => {
    setIsDialogFiltersOpen(false);
  };

  const onInquiryItemClick = useCallback(
    (id: string) => {
      const selectedInquiry = inquiriesList?.find(
        (inquiry: InquirySearchType) => inquiry.inquiryId === id
      );
      selectedInquiry && setSelectedInquiry(selectedInquiry);
      selectedInquiry && setSelectedInquiryId(selectedInquiry.inquiryId);
      if (selectedInquiry) {
        ActivityLogic.toActivityService({
          action: "selectInquiry_cdash",
          actionId: id,
          actionIdType: "inquiry",
          actionMessage: id,
          locationInApp: "LeftContainer.tsx",
        });
      }
    },
    [inquiriesList, setSelectedInquiry]
  );

  const handleDropDownFiltersToggle = useCallback(() => {
    if (isNoSearchMatchesInquiry) {
      isDesktop
        ? setIsOpenFiltersDropDown(false)
        : setIsDialogFiltersOpen(false);

      return;
    }

    isDesktop
      ? setIsOpenFiltersDropDown(prevOpen => !prevOpen)
      : setIsDialogFiltersOpen(prevOpen => !prevOpen);
  }, [isDesktop, isNoSearchMatchesInquiry]);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsOpenFiltersDropDown(false);
    } else if (event.key === "Escape") {
      setIsOpenFiltersDropDown(false);
    }
  }, []);

  const eventChangeHandler = useCallback((newEventValue: string | null) => {
    setEventValue(newEventValue);
  }, []);

  const trackFilterUsed = (value: string, key: string) => {
    if (!value) {
      return;
    }

    ActivityLogic.toActivityService({
      action: "filter_cdash",
      actionId: value,
      actionIdType: "dashFilter",
      actionMessage: value,
      data1Type: "filterType",
      data1Value: key,
      data2Type: "value",
      data2Value: value,
      locationInApp: "LeftContainer.tsx",
    });
  };

  //select inquiry initially from list by default or from url param
  useLayoutEffect(() => {
    if (isDesktop && inquiriesList?.length) {
      if (initialInquiryId) {
        const initialSelectedIquiry = inquiriesList.find(
          (inquiry: InquirySearchType) => inquiry.inquiryId === initialInquiryId
        );

        if (initialSelectedIquiry) {
          setSelectedInquiry(initialSelectedIquiry);
          setSelectedInquiryId(initialInquiryId);
        } else {
          const initialSelectedIquiry = inquiriesList[0];
          setSelectedInquiry(initialSelectedIquiry);
          setSelectedInquiryId(initialSelectedIquiry.inquiryId);
        }

        return;
      }
      const initialSelectedIquiry = inquiriesList[0];
      setSelectedInquiry(initialSelectedIquiry);
      setSelectedInquiryId(initialSelectedIquiry.inquiryId);
    }
  }, [isDesktop, inquiriesList, setSelectedInquiry, initialInquiryId]);

  useEffect(() => {
    if (!appliedFilters && appliedFiltersAmount) {
      setAppliedFiltersAmount(0);
      setStatusFilters(defaultStatusFilters);
      startDateValue && setStartDateValue(null);
      endDateValue && setEndDateValue(null);
      eventValue && setEventValue("");
    }

    if (appliedFilters && !appliedFiltersAmount) {
      const filtersValues = [
        appliedFilters.statusFilters,
        appliedFilters.startDateValue,
        appliedFilters.endDateValue,
        appliedFilters.eventValue,
      ];

      const appliedFiltersAmount = AppliedFiltersNumberHelper.getAppliedFiltersAmount(filtersValues);
      setAppliedFiltersAmount(appliedFiltersAmount);

      setStatusFilters(appliedFilters.statusFilters);
      setStartDateValue(appliedFilters.startDateValue);
      setEndDateValue(appliedFilters.endDateValue);
      setEventValue(appliedFilters.eventValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters, appliedFiltersAmount]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapseButtonVisible(isLeftContainerCollapsed);
    }, 300);

    return () => clearTimeout(timer);
  }, [isLeftContainerCollapsed]);

  const applyFiltersHandler = useCallback(() => {
    setIsOpenFiltersDropDown(false);

    setAppliedFilters({
      statusFilters,
      startDateValue,
      endDateValue,
      eventValue,
    });

    const filtersValues = [
      statusFilters,
      startDateValue,
      endDateValue,
      eventValue,
    ];

    trackFilterUsed(JSON.stringify(statusFilters), "statusFilters");
    trackFilterUsed(startDateValue ? `${startDateValue}` : "", "startDateValue");
    trackFilterUsed(endDateValue ? `${endDateValue}` : "", "endDateValue");
    trackFilterUsed(eventValue ?? "", "eventValue");   

    const appliedFiltersAmount = AppliedFiltersNumberHelper.getAppliedFiltersAmount(filtersValues);
    setAppliedFiltersAmount(appliedFiltersAmount);

    isDialogFiltersOpen && setIsDialogFiltersOpen(!isDialogFiltersOpen);
    setRequestInquiriesSkip(0);
  }, [
    setAppliedFilters,
    statusFilters,
    startDateValue,
    endDateValue,
    eventValue,
    isDialogFiltersOpen,
    setRequestInquiriesSkip,
  ]);

  return (
    <div className={styles.inquiriesContainer}>
      <Typography className={styles.inquiriesListTitle} variant="h4">
        Inquiries&nbsp;
        <span
          className={styles.inquiriesListAmount}
          data-testid={"inquiriesAmount"}
        >
          {NumberHelper.withCommas(totalInquiriesAmount.toString(), true)}
        </span>
      </Typography>
      {isCollapseButtonVisible && (
        <BaseButton
          className={styles.buttonCollapse}
          onClick={onClickCollapseHandler}
        >
          <Image src={RightIconCollapse} alt="collapse icon" />
        </BaseButton>
      )}
      <Stack className={styles.filters}>
        <div className={styles.filtersWrapper}>
          {appliedFiltersAmount ? (
            <span className={styles.appliedFiltersAmount}>
              {appliedFiltersAmount}
            </span>
          ) : null}
          <InputFilter
            searchValue={searchValue}
            isNoFiltersMatchesInquiry={isNoFiltersMatchesInquiry}
            setSearchValue={setSearchValue}
            handleDropDownFiltersToggle={handleDropDownFiltersToggle}
            onFocusInputHandler={onFocusInputHandler}
          />
          <FilterResponsiveWrapper
            isOpenFiltersDropDown={isOpenFiltersDropDown}
            isDialogFiltersOpen={isDialogFiltersOpen}
            isDesktop={isDesktop}
            handleClosePopperDropDown={handleClosePopperDropDown}
            handleListKeyDown={handleListKeyDown}
            handleMobileDialogFiltersClose={handleMobileDialogFiltersClose}
          >
            <FiltersList
              statusFilters={statusFilters}
              endDateValue={endDateValue}
              startDateValue={startDateValue}
              eventValue={eventValue}
              eventsList={eventsList}
              selectEventCloseHandler={selectEventCloseHandler}
              selectEventOpenHandler={selectEventOpenHandler}
              setEndDateValue={setEndDateValue}
              setStartDateValue={setStartDateValue}
              setStatusFilters={setStatusFilters}
              eventChangeHandler={eventChangeHandler}
              clearFiltersHandler={clearFiltersHandler}
              applyFiltersHandler={applyFiltersHandler}
            />
          </FilterResponsiveWrapper>
        </div>
      </Stack>
      {!isFetching ? (
        <>
          <EventList
            isNoSearchMatchesInquiry={isNoSearchMatchesInquiry}
            isNoFiltersMatchesInquiry={isNoFiltersMatchesInquiry}
            isErrorBySearch={isErrorBySearch}
            isErrorByFiltering={isErrorByFiltering}
            inquiriesList={inquiriesList}
            selectedInquiryId={selectedInquiryId}
            isPaginationHidden={isEventListWithoutPagination}
            onInquiryItemClick={onInquiryItemClick}
            setSearchValue={setSearchValue}
            setAppliedFilters={setAppliedFilters}
            setIsFetching={setIsFetching}
            setIsURLWithQueryParams={setIsURLWithQueryParams}
          />
          <PaginationComponent
            isPaginationHidden={isPaginationHidden}
            paginationPagesAmount={paginationPagesAmount}
            paginationCurrentPage={paginationCurrentPage}
            responsiveSize={true}
            handleNewPage={handleNewPage}
          />
        </>
      ) : (
        <BaseLoader size={24}/>
      )}
    </div>
  );
}

export default LeftContainer;
