import React, { useMemo } from "react";
import cn from "classnames";
import { BookedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/BookedMessage";
import { ChangedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChangedMessage";
import { ClosedLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ClosedLogMessage";
import { EmailLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/EmailLogMessage";
import { FeedbackMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/FeedbackMessage";
import { ReopenMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ReopenMessage";
import { SubmittedMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/SubmittedMessage";
import { AuthLogic, InquiryLogLogic } from "@/src/model";
import { InquiryLog, InquiryLogEventTypes, InquiryLogSourceTypes } from "@/src/type/blaceV2";
import styles from "./LogListItemManager.module.scss";

interface LogListItemManagerProps {
  inquiryMessage: InquiryLog;
}

function LogListItemManager({ inquiryMessage }: LogListItemManagerProps) {
  const LogListItemComponent = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case InquiryLogEventTypes.Submit:
        return <SubmittedMessage message={inquiryMessage} />;
      case InquiryLogEventTypes.Change:
        const isSomethingChanged = InquiryLogLogic.hasEventDetailsChanges(inquiryMessage);
        return (
          isSomethingChanged && <ChangedMessage message={inquiryMessage} />
        );
      case InquiryLogEventTypes.Email:
        return <EmailLogMessage message={inquiryMessage} />;
      case InquiryLogEventTypes.Closed:
        return <ClosedLogMessage message={inquiryMessage} />;
      case InquiryLogEventTypes.Reopen:
        return <ReopenMessage message={inquiryMessage} />;
      case InquiryLogEventTypes.Booked:
        return <BookedMessage message={inquiryMessage} />;
      case InquiryLogEventTypes.NPS:
        return <FeedbackMessage message={inquiryMessage} />;
      default:
        return <></>;
    }
  }, [inquiryMessage]);

  const logListItemClasses = useMemo(() => {
    // TODO : migrate to just `isFromCurrentUser` after BE fix (https://blace.atlassian.net/browse/B20-759)
    const isEmail = inquiryMessage.eventType === InquiryLogEventTypes.Email;
    const isFromCurrentUser = inquiryMessage.userId === AuthLogic.getV2AuthUserId();
    const isFromClient = inquiryMessage.sourceType === InquiryLogSourceTypes.Client;
    return cn(styles.logListItem, {
      [styles.isFromRight]: isEmail ? isFromCurrentUser : isFromClient,
      [styles.isEmpty]: !InquiryLogLogic.hasEventDetailsChanges(inquiryMessage),
    });
  }, [inquiryMessage]);

  return <div className={cn(logListItemClasses)} data-testid="log-list-manager">{LogListItemComponent}</div>;
}

export default LogListItemManager;
