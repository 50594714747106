import React, { IframeHTMLAttributes } from "react";

const IframeHTMLContent = React.forwardRef(function IframeHTMLContent(
  props: IframeHTMLAttributes<HTMLIFrameElement>,
  ref?: React.LegacyRef<HTMLIFrameElement>
) {
  return (
    <iframe
      title="HTML Content"
      width="100%"
      frameBorder="0"
      ref={ref}
      {...props}
    />
  );
});

export default IframeHTMLContent;
