import { decode } from "html-entities";
import { LineClampText } from "@src/component/base";
import {
  DetailSectionInfo,
  DetailSectionInfoBody,
} from "@src/component/partial";

interface VenueAboutProps {
  venueDescription?: string;
}

function VenueAbout({ venueDescription }: VenueAboutProps) {
  if (!venueDescription) {
    return false;
  }

  return <DetailSectionInfo
    title=""
    Component={
      <LineClampText
        lineHeight={23}
        ComponentWithText={
          <DetailSectionInfoBody tag="h2">
            {decode(venueDescription)}
          </DetailSectionInfoBody>
        }
      />
    }
  />;
}

export default VenueAbout;
