import { useCallback, useEffect, useMemo, useState } from "react";
import type { MouseEvent } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import VendorToggleIcon from "@/public/icons/vendorToggleIcon.svg";
import VenueToggleIcon from "@/public/icons/venueToggleIcon.svg";
import { useApp } from "@/src/component/provider";
import { BlaceV2Type } from "@/src/type";
import styles from "./DataTypeButtonGroup.module.scss";

interface DataTypeButtonGroupProps {
  isCitySelected: boolean;
}

function DataTypeButtonGroup({ isCitySelected }: DataTypeButtonGroupProps) {
  const app = useApp();
  const [formats, setFormats] = useState<
    BlaceV2Type.SearchType.SearchDataType[]
  >(
    app?.searchFilters?.getFilterData<BlaceV2Type.SearchType.SearchDataType[]>(
      "dataType"
    ) ?? []
  );

  /**
   * change the filter when the search state has changed from a tag being removed with this type
   */
  useEffect(() => {
    function compareArrays(a: Array<string>, b: Array<string>): boolean {
      return (
        a.length === b.length &&
        a.every((element, index) => element === b[index])
      );
    }

    const a = app.searchFilters?.filterState.dataType ?? [];
    const b = formats ?? [];

    if (!compareArrays(a, b)) {
      setFormats(a);
    }
  }, [app?.searchFilters, formats]);

  const handleFormat = (
    event: MouseEvent<HTMLElement>,
    newFormats: BlaceV2Type.SearchType.SearchDataType
  ) => {
    const a = app.searchFilters?.filterState.dataType ?? [];
    const value = a.includes(newFormats) ? [] : [newFormats];
    app?.searchFilters?.setFilterData("dataType", value);
  };

  const textColorClass = useMemo(
    () =>
      isCitySelected ? styles.textColorSelected : styles.textColorUnSelected,
    [isCitySelected]
  );

  const getCurrentBorderBottomClass = (
    formats: Array<string>,
    value: string,
    isCitySelected: boolean
  ) => {
    if (isCitySelected) {
      if (formats.length) {
        return formats.includes(value)
          ? styles.borderBottomWhite
          : styles.borderBottomDark;
      }
      return styles.borderBottomDark;
    }

    if (formats.length) {
      return formats.includes(value)
        ? styles.borderBottomBlack
        : styles.borderBottomDark;
    }
    return styles.borderBottomDark;
  };

  const getToggleButtonClasses = useCallback(
    (value: string) => {
      const currentBorderBottomClass = getCurrentBorderBottomClass(
        formats,
        value,
        isCitySelected
      );
      return [
        currentBorderBottomClass,
        textColorClass,
        styles.toggleButtonCommon,
      ];
    },
    [isCitySelected, formats]
  );

  const getToggleImgFilter = useCallback(
    (value: BlaceV2Type.SearchType.SearchDataType) => {
      if (isCitySelected) {
        return { filter: "none" };
      }
      return {
        filter: (formats ?? []).includes(value)
          ? "invert(100%)"
          : "invert(50%)",
      };
    },
    [isCitySelected, formats]
  );

  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        color="secondary"
        value={formats}
        onChange={handleFormat}
        aria-label="Search Buttons for Venue, Vendor"
        size="small"
        exclusive={true}
      >
        <ToggleButton
          value="venue"
          aria-label="Venue Button"
          className={cn(getToggleButtonClasses("venue"))}
        >
          <Image
            src={VenueToggleIcon}
            alt="venue card icon"
            style={getToggleImgFilter(BlaceV2Type.SearchTypes.Venue)}
          />
          Venue
        </ToggleButton>
        <ToggleButton
          value="vendor"
          aria-label="Vendor Button"
          className={cn(getToggleButtonClasses("vendor"))}
        >
          <Image
            src={VendorToggleIcon}
            alt="vendor card icon"
            style={getToggleImgFilter(BlaceV2Type.SearchTypes.Vendor)}
          />
          Vendor
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default DataTypeButtonGroup;
