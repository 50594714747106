import { Typography } from "@mui/material";
import { decode } from "html-entities";
import { LineClampText } from "@/src/component/base";
import { VenueQuickFacts } from "@/src/component/view/VenueDetail/component/VenueQuickFacts";
import { PRICE_STARTING_PRE_TEXT } from "@/src/const";
import { useBreakPointDown } from "@/src/hook";
import { ImageLogic, VenueLogic } from "@/src/model";
import { ComponentType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import {
  CarouselImage,
  DetailSectionInfo,
  DetailSectionInfoBody,
} from "@src/component/partial";
import styles from "./VenueRoom.module.scss";

interface VenueRoomItemProps {
  room: SearchType.SearchRoom;
}

function VenueRoomItemV2({ room }: VenueRoomItemProps) {
  const isMobile = useBreakPointDown("md");

  const images: ComponentType.Carousel.ImageType[] = (room.images ?? []).map(
    (img, i): ComponentType.Carousel.ImageType => ({
      url: ImageLogic.getImageUrl(img),
      id: `${img}-${i}`,
      alt: `${room.name} Room Image ${i}`,
    })
  );

  return (
    <DetailSectionInfo
      title={room.name}
      Component={
        <div className={styles.venueRoomItem}>
          <div className={styles.venueRoomItemImageContainer}>
            <CarouselImage
              images={images}
              autoPlay={true}
              imageClassName={styles.venueRoomItemImage}
              navButtonsAlwaysVisible={!isMobile}
            />
          </div>
          <div className={styles.venueRoomItemAbout}>
            <div className={styles.venueRoomItemPricing}>
              <>
                {/*TODO: should we show "Pre Text" only when room is showing ? (non default text) ?*/}
                <Typography variant="h6">{PRICE_STARTING_PRE_TEXT}</Typography>
                <Typography variant="h2">
                  {VenueLogic.formatVenueRoomPricing(room)}
                </Typography>
              </>
              {/*
              //TODO: add back when inquiry supports adding rooms / vendors again
              <div className={styles.venueRoomItemPricingBttn}>
                <BaseButton
                  type="button"
                  variant="outlined"
                  color="secondary"
                >
                  Add to Inquiry
                </BaseButton>
              </div>
              */}
            </div>
            <VenueQuickFacts
              numberOfFloors={
                (room?.numberOfFloors ?? 0) > 0
                  ? `${room.numberOfFloors} Floor${room.numberOfFloors > 1 ? "s" : ""}`
                  : ""
              }
              maxHeadCount={room.maxCapacity}
              sqFootage={room.sqFootage}
            />
            <LineClampText
              lineHeight={23}
              linesToShowDesktop={7}
              linesToShowMobile={4}
              ComponentWithText={
                <DetailSectionInfoBody tag="h4">
                  {decode(room.description ?? "")}
                </DetailSectionInfoBody>
              }
            />
            {/*
            //TODO: add back when inquiry supports adding rooms / vendors again
            <div className={styles.venueRoomItemAddInquiryBttn}>
              <BaseButton
                type="button"
                variant="outlined"
                color="secondary"
                fullWidth
              >
                Add to Inquiry
              </BaseButton>
            </div>
            */}
          </div>
        </div>
      }
    />
  );
}

export default VenueRoomItemV2;
