import { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import Image, { StaticImageData } from "next/image";
import styles from "./IconBlock.module.scss";

interface IconBlockProps {
  icon?: string | StaticImageData;
}
function IconBlock({ icon, children }: PropsWithChildren<IconBlockProps>) {
  const textClasses = cn(styles.panelText, { [styles.withoutIcon]: !icon });
  return (
    <div className={styles.panelDetailsWrapper}>
      {icon && (
        <Image
          src={icon}
          alt="icon"
          width="16"
          height="16"
          className={styles.icon}
        />
      )}
      <Typography variant="subtitle1" className={textClasses}>
        {children}
      </Typography>
    </div>
  );
}

export default IconBlock;
