import { ChangeEvent, memo, useEffect, useState } from "react";
import Image from "next/image";
import { useDebouncedCallback } from "use-debounce";
import EventSearchIcon from "@/public/icons/eventSearch.svg";
import FiltersButtonIcon from "@/public/icons/filtersIcon.svg";
import { BaseButton } from "@/src/component/base";
import { ActivityLogic } from "@/src/model";
import styles from "./InputFilter.module.scss";

interface InputFilterProps {
  searchValue: string;
  isNoFiltersMatchesInquiry: boolean;
  setSearchValue: (val: string) => void;
  onFocusInputHandler: () => void;
  handleDropDownFiltersToggle: () => void;
}

const InputFilter = ({
  searchValue,
  isNoFiltersMatchesInquiry,
  handleDropDownFiltersToggle,
  onFocusInputHandler,
  setSearchValue,
}: InputFilterProps) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    !searchValue.length && setValue("");
  }, [searchValue]);

    
  function trackSearchUsed(val: string) {
    if (!val) {
      return;
    }

    ActivityLogic.toActivityService({
      action: "search_cdash",
      actionId: val,
      actionIdType: "searchValue",
      actionMessage: val,
      locationInApp: "InputFilter.tsx",
    });
  }

  const debouncedCallback = useDebouncedCallback((value: string) => {
    setSearchValue(value);
    trackSearchUsed(value);
  }, 500);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedCallback(newValue);
  };
  return (
    <div className={styles.inputWrapper}>
      <label htmlFor="event-search">
        <Image src={EventSearchIcon} alt="event search icon" />
      </label>
      <input
        value={value}
        placeholder="Search"
        className={styles.filtersInput}
        id="event-search"
        onChange={handleInputChange}
        onFocus={onFocusInputHandler}
        disabled={isNoFiltersMatchesInquiry}
        data-testid="test-input"
      />
      <BaseButton
        className={styles.filterButton}
        aria-haspopup="true"
        onClick={handleDropDownFiltersToggle}
      >
        <Image src={FiltersButtonIcon} alt="filter event icon" />
      </BaseButton>
    </div>
  );
};

export default memo(InputFilter);
