import React from "react";
import styles from "./LogProfileCircle.module.scss";

interface LogProfileCircleProps {
  initials: string;
  imageUrl?: string;
  imageAlt?: string;
}

function LogProfileCircle({ initials, imageUrl, imageAlt }: LogProfileCircleProps) {
  return imageUrl ? (
    <img
      src={imageUrl}
      alt={imageAlt}
      width={40}
      height={40}
      className={styles.imageWrapper}
    />
  ) : (
    <div className={styles.userInitial}>
      <p className={styles.userInitialText}>{initials}</p>
    </div>
  );
}

export default LogProfileCircle;
