import { Box } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { KEYS } from "@/src/const";
import { BlaceV2Type } from "@/src/type";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import styles from "./BookedMessage.module.scss";

interface BookedMessageProps {
  message: BlaceV2Type.InquiryLog;
}

function BookedMessage({ message }: BookedMessageProps) {
  const ASSET_URL: string = SharedConfigManager.getValue(KEYS.ASSET_URL);

  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper} data-testid="booked-message">
        <img
          src={`${ASSET_URL}/confrimBookingBg.png`}
          height="100%"
          width="100%"
        />
      </Box>
    </ChatLogMessage>
  );
}

export default BookedMessage;
