import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import { ArrowSVG, CheckFillSVG, ErrorFillSVG } from "@/src/asset";
import { ChangedShortProperties } from "@/src/type/blaceV2";
import { FormattedDateHelper } from "@/src/util/helper";
import styles from "./ChangedEventDataItem.module.scss";

interface ChangedEventDataItemProps {
  oldValue?: string | number | boolean;
  newValue?: string | number | boolean;
  title: ChangedShortProperties;
}
function ChangedEventDataItem({
  oldValue,
  newValue,
  title,
}: ChangedEventDataItemProps) {
  const isValueChanged = oldValue !== newValue;
  const getDataItemContent = useMemo(() => {
    const showStartDateValue = ChangedShortProperties.StartDate === title;

    const showEndDateValue = ChangedShortProperties.EndDate === title;

    const showFlexibleDatesValue =
      ChangedShortProperties.DateFlexibility === title;

    const showGuestsValue = ChangedShortProperties.Guests === title;

    if (!oldValue && !newValue) {
      return null;
    }

    if (showStartDateValue || showEndDateValue) {
      return (
        <>
          <Typography className={styles.marked} data-testid="oldDate">
            {!!oldValue &&
              FormattedDateHelper.formatExtendedDate(Number(oldValue))}
          </Typography>
          <ArrowSVG />
          <Typography className={styles.marked} data-testid="newDate">
            {!!newValue &&
              FormattedDateHelper.formatExtendedDate(Number(newValue))}
          </Typography>
        </>
      );
    }

    if (showFlexibleDatesValue) {
      return (
        <>
          <Typography
            className={cn(styles.marked, styles.dateFlexibilityElementWrapper)}
          >
            {oldValue ? <CheckFillSVG /> : <ErrorFillSVG />}
            {oldValue ? "Yes" : "No"}
          </Typography>
          <ArrowSVG />
          <Typography
            className={cn(styles.marked, styles.dateFlexibilityElementWrapper)}
          >
            {newValue ? <CheckFillSVG /> : <ErrorFillSVG />}
            {newValue ? "Yes" : "No"}
          </Typography>
        </>
      );
    }

    if (showGuestsValue) {
      return (
        <>
          {oldValue && <Typography data-testid="oldGuests" className={styles.marked}>{oldValue}</Typography>}
          <ArrowSVG />
          {newValue && <Typography className={styles.marked}>{newValue}</Typography>}
        </>
      );
    }
  }, [oldValue, newValue, title]);

  if (!isValueChanged) {
    return null;
  }

  return (
    getDataItemContent && (
      <div className={styles.dataItemWrapper}>
        <Typography className={styles.dataItemTitle}>{title}</Typography>
        <div className={styles.dataItemContent}>{getDataItemContent}</div>
      </div>
    )
  );
}

export default ChangedEventDataItem;
