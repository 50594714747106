import { Box, Typography } from "@mui/material";
import { ReachedLimitWarningSVG } from "@/src/asset";
import { BaseModal } from "@/src/component/base";
import styles from "./ReachedLimitPopup.module.scss";

interface ReachedLimitPopupProps {
  isLimitReachedPopupVisible: boolean;
  handleDialogClose: () => void;
}

function ReachedLimitPopup({
  isLimitReachedPopupVisible,
  handleDialogClose,
}: ReachedLimitPopupProps) {
  return (
    <BaseModal
      dialogProps={{
        open: isLimitReachedPopupVisible,
        onClose: () => handleDialogClose(),
      }}
      useSlide={false}
      isMobileDraggable={false}
      onExit={() => handleDialogClose()}
    >
      {
        <Box className={styles.reachedLimitPopupWrapper}>
          <div className={styles.limitWarnIcon}><ReachedLimitWarningSVG/></div>
          <Typography className={styles.headerPopup}>
            Inquiry Limit Reached
          </Typography>
          <Typography className={styles.descriptionPopup}>
            We appreciate your enthusiasm, but we can only handle a certain
            number of inquiries at once. Please take a break before submitting
            more inquires
          </Typography>
        </Box>
      }
    </BaseModal>
  );
}

export default ReachedLimitPopup;
