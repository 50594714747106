import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import LayersTwoToneIcon from "@mui/icons-material/LayersTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import VerticalAlignTopTwoToneIcon from "@mui/icons-material/VerticalAlignTopTwoTone";
import { NumberHelper } from "@/src/util";
import styles from "./VenueQuickFacts.module.scss";
import VenueQuickFactsItem from "./VenueQuickFactsItem";

interface VenueQuickFactsProps {
  numberOfFloors?: string;
  sqFootage?: number;
  ceilingHeight?: number;
  maxHeadCount?: number;
}

function VenueQuickFacts({
  numberOfFloors,
  sqFootage,
  ceilingHeight,
  maxHeadCount,
}: VenueQuickFactsProps) {
  if (
    typeof numberOfFloors !== "string" &&
    (typeof sqFootage !== "number" || (sqFootage ?? 0) <= 0) &&
    (typeof ceilingHeight !== "number" || (ceilingHeight ?? 0) <= 0) &&
    (typeof maxHeadCount !== "number" || (maxHeadCount ?? 0) <= 0)
  ) {
    return <></>;
  }

  return (
    <div className={styles.venueQuickFacts}>
      {typeof numberOfFloors === "string" && (
        <VenueQuickFactsItem
          Icon={<DomainTwoToneIcon />}
          fact={numberOfFloors}
        />
      )}
      {typeof sqFootage === "number" && sqFootage > 0 && (
        <VenueQuickFactsItem
          Icon={<LayersTwoToneIcon />}
          fact={`${NumberHelper.withCommas(`${sqFootage}`)} Sq. Ft.`}
        />
      )}
      {typeof maxHeadCount === "number" && maxHeadCount > 0 && (
        <VenueQuickFactsItem
          Icon={<PeopleAltTwoToneIcon />}
          fact={`${NumberHelper.withCommas(`${maxHeadCount}`)} Guest Max`}
        />
      )}
      {typeof ceilingHeight === "number" && ceilingHeight > 0 && (
        <VenueQuickFactsItem
          Icon={<VerticalAlignTopTwoToneIcon />}
          fact={`${ceilingHeight} Ft. Ceiling`}
        />
      )}
    </div>
  );
}

export default VenueQuickFacts;
