import { Typography } from "@mui/material";
import cn from "classnames";
import { BaseButton } from "@/src/component/base";
import styles from "./ToastInfo.module.scss";

interface ToastInfoProps {
  handleOnClick: () => void;
  inquiryType?: string;
  showButton?: boolean;
}

function ToastInfo({ handleOnClick, inquiryType, showButton }: ToastInfoProps) {
  return (
    <div className={styles.toastContainer}>
      <Typography className={styles.toastMessage} data-testid="toast-info">
        {`Your inquiry has been sent. The ${inquiryType} will be in touch shortly.`}
      </Typography>
      {showButton && (
        <BaseButton
          variant="outlined"
          className={cn(
            styles.toastButton,
            showButton ? styles.toastMessageSuccess : undefined
          )}
          onClick={handleOnClick}
        >
          Inquiry Details
        </BaseButton>
      )}
    </div>
  );
}

export default ToastInfo;
