import React from "react";
import { Box, Typography } from "@mui/material";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { InquiryLog } from "@/src/type/blaceV2";
import styles from "./SubmittedMessage.module.scss";

interface SubmittedMessageProps {
  message: InquiryLog;
}

function SubmittedMessage({ message }: SubmittedMessageProps) {
  return (
    <ChatLogMessage inquiryMessage={message} contentWithBorder>
      <Box className={styles.contentWrapper} data-testid="submitted-message">
        <Typography className={styles.contentTitle}>
          Event description
        </Typography>
        <Typography className={styles.contentText}>
          {message?.shortProperties?.notes}
        </Typography>
      </Box>
    </ChatLogMessage>
  );
}

export default SubmittedMessage;
