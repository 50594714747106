import {
  CapacityBoardroomSVG,
  CapacityClassroomSVG,
  CapacityDiningSVG,
  CapacityStandingSVG,
  CapacityTheaterSVG,
} from "@/src/asset";
import { SearchType } from "@/src/type/blaceV2";
import { NumberHelper } from "@/src/util";
import { DetailSectionInfo } from "@src/component/partial";
import styles from "./VenueCapacity.module.scss";
import VenueCapacityItem from "./VenueCapacityItem";

interface VenueCapacityProps {
  venueCapacity?: SearchType.SearchCapacity;
}

function VenueCapacity({ venueCapacity }: VenueCapacityProps) {
  if (
    !NumberHelper.isNull(venueCapacity?.seated, 0) &&
    !NumberHelper.isNull(venueCapacity?.standing, 0) &&
    !NumberHelper.isNull(venueCapacity?.theater, 0) &&
    !NumberHelper.isNull(venueCapacity?.boardroom, 0) &&
    !NumberHelper.isNull(venueCapacity?.classroom, 0)
  ) {
    return <></>;
  }

  return (
    <DetailSectionInfo
      title="Capacity"
      Component={
        <div className={styles.capacityContainer}>
          <div className={styles.capacityGrid}>
            <VenueCapacityItem
              Icon={<CapacityStandingSVG />}
              name="Standing"
              capacity={venueCapacity?.standing}
            />
            <VenueCapacityItem
              Icon={<CapacityDiningSVG />}
              name="Dining"
              capacity={venueCapacity?.seated}
            />
            <VenueCapacityItem
              Icon={<CapacityTheaterSVG />}
              name="Theater"
              capacity={venueCapacity?.theater}
            />
            <VenueCapacityItem
              Icon={<CapacityBoardroomSVG />}
              name="Boardroom"
              capacity={venueCapacity?.boardroom}
            />
            <VenueCapacityItem
              Icon={<CapacityClassroomSVG />}
              name="Classroom"
              capacity={venueCapacity?.classroom}
            />
          </div>
        </div>
      }
    />
  );
}

export default VenueCapacity;
