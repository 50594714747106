import React, { Dispatch, memo, SetStateAction } from "react";
import { Typography } from "@mui/material";
import { BaseButton } from "@/src/component/base";
import { EventDateFilter } from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/EventDateFilter";
import { EventFilter } from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/EventFilter";
import {
  StatusFilter,
  StatusFilters,
} from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/StatusFilter";
import { Event } from "@/src/type/blaceV2/BlaceV2Type";
import styles from "./FiltersList.module.scss";

interface FiltersListProps {
  statusFilters: StatusFilters;
  endDateValue: Date | null;
  startDateValue: Date | null;
  eventValue: string | null;
  eventsList: Event[] | [];
  selectEventCloseHandler: () => void;
  selectEventOpenHandler: () => void;
  setEndDateValue: Dispatch<SetStateAction<Date | null>>;
  setStartDateValue: Dispatch<SetStateAction<Date | null>>;
  setStatusFilters: Dispatch<SetStateAction<StatusFilters>>;
  eventChangeHandler: (newValue: string | null) => void;
  clearFiltersHandler: () => void;
  applyFiltersHandler: () => void;
}

function FiltersList({
  statusFilters,
  endDateValue,
  startDateValue,
  eventValue,
  eventsList,
  selectEventCloseHandler,
  selectEventOpenHandler,
  setEndDateValue,
  setStartDateValue,
  setStatusFilters,
  eventChangeHandler,
  clearFiltersHandler,
  applyFiltersHandler,
}: FiltersListProps) {
  const isAnyFilterApplied =
    endDateValue ||
    startDateValue ||
    eventValue ||
    statusFilters.open ||
    statusFilters.booked ||
    statusFilters.completed ||
    statusFilters.closed;
  return (
    <>
      <div className={styles.filterItem}>
        <Typography variant="h4" className={styles.filterTitle}>
          Status
        </Typography>
        <StatusFilter
          statusFilters={statusFilters}
          setStatusFilters={setStatusFilters}
        />
      </div>
      <div className={styles.filterItem}>
        <Typography variant="h4" className={styles.filterTitle}>
          Event Date
        </Typography>
        <EventDateFilter
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setStartDateValue={setStartDateValue}
          setEndDateValue={setEndDateValue}
        />
      </div>
      <div className={styles.filterItem}>
        <Typography variant="h4" className={styles.filterTitle}>
          Event
        </Typography>
        <EventFilter
          eventsList={eventsList}
          eventValue={eventValue}
          selectEventOpenHandler={selectEventOpenHandler}
          selectEventCloseHandler={selectEventCloseHandler}
          eventChangeHandler={eventChangeHandler}
        />
      </div>
      <div className={styles.clearFiltersSection}>
        <BaseButton
          disabled={!isAnyFilterApplied}
          className={styles.clearButton}
          onClick={clearFiltersHandler}
          data-testid="clear-all"
        >
          Clear all filters
        </BaseButton>
        <BaseButton
          disabled={!isAnyFilterApplied}
          className={styles.applyButton}
          onClick={applyFiltersHandler}
          data-testid="apply"
        >
          Apply
        </BaseButton>
      </div>
    </>
  );
}

export default memo(FiltersList);
