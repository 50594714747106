import { InquirySecondaryStatusLabels } from "@/src/type/blaceV2";
import styles from "./InquirySecondaryStatusLabel.module.scss";

interface InquirySecondaryStatusLabelProps {
  inquiryStatus: InquirySecondaryStatusLabels | null;
}

function InquirySecondaryStatusLabel({
  inquiryStatus,
}: InquirySecondaryStatusLabelProps) {
  if (!inquiryStatus) {
    return null;
  }
  const renderInquiryStatusLabel = (
    inquiryStatus: InquirySecondaryStatusLabels
  ) => {
    switch (inquiryStatus) {
      case InquirySecondaryStatusLabels.Due:
        return (
          <div className={styles.due}>{InquirySecondaryStatusLabels.Due}</div>
        );
      case InquirySecondaryStatusLabels.Overdue:
        return (
          <div className={styles.overdue}>
            {InquirySecondaryStatusLabels.Overdue}
          </div>
        );
      case InquirySecondaryStatusLabels.Processing:
        return (
          <div className={styles.processing}>
            {InquirySecondaryStatusLabels.Processing}
          </div>
        );
    }
  };

  return <>{renderInquiryStatusLabel(inquiryStatus)}</>;
}

export default InquirySecondaryStatusLabel;
