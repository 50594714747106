import { useCallback, useEffect, useMemo, useState } from "react";
import { BaseLoader } from "@/src/component/base/BaseLoader";
import { ToastMessage } from "@/src/component/base/ToastMessage";
import { ReachedLimitPopup } from "@/src/component/partial/ReachedLimitPopup";
import { SearchResult } from "@/src/component/partial/SimilarCarousel/SimilarCarousel";
import { SendInquiryDialog } from "@/src/component/view/InquiryComplete/component/SendInquiryDialog";
import { ToastInfo } from "@/src/component/view/InquiryComplete/component/ToastInfo";
import { VendorVenueSection } from "@/src/component/view/InquiryComplete/component/VendorVenueSection";
import { useNextNavigation, useReachedLimitInquiry } from "@/src/hook";
import { CMSLogic, SimilarSearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { Search } from "@/src/type/blaceV2/search/SearchType";
import styles from "./InquiryComplete.module.scss";
interface InquiryCompleteProps {
  inquiry: BlaceV2Type.EventType.Inquiry;
  venue?: SearchType.SearchItem | null;
  vendor?: SearchType.SearchItem | null;
  venuesList: Search[] | null;
  vendorsList: Search[] | null;
}

function InquiryComplete({
  inquiry,
  vendor,
  venue,
  venuesList,
  vendorsList,
}: InquiryCompleteProps) {
  const navigation = useNextNavigation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<
    SearchType.SearchItem | undefined
  >(undefined);
  const [selectedVenue, setSelectedVenue] = useState<
    SearchType.SearchItem | undefined
  >(undefined);
  const [selectedCatalogId, setSelectedCatalogId] = useState<
    string | undefined
  >(undefined);
  const [selectedSearchId, setSelectedSearchId] = useState<string | undefined>(
    undefined
  );
  const [searchedSlug, setSearchedSlug] = useState<string>("|none|");
  const [searchResults, setSearchResults] = useState<
    BlaceV2Type.SearchType.SearchItem[] | undefined
  >(undefined);

  const backgroundImageCity = useMemo(
    () => searchResults?.[0].regions?.[0],
    [searchResults]
  );

  const {
    inquiryType,
    inquiryId,
    endDate,
    guests,
    notes,
    startDate,
    eventName,
    phone,
    budget,
    slug,
  } = inquiry;

  const inquiryFormData = {
    endDate,
    guests,
    notes,
    startDate,
    eventName,
    phone,
    budget,
  };

  const {
    isLimitReachedPopupVisible,
    handleDialogClose,
    setInquiryLimitReached,
  } = useReachedLimitInquiry();

  const isVenue = useMemo(() => {
    return inquiryType === BlaceV2Type.InquiryTypes.Venue;
  }, [inquiryType]);

  const handleOnClick = () => {
    const inquiryLink = CMSLogic.constructLink(
      `/dashboard?inquiryId=${inquiryId}`
    );

    navigation.router.push(inquiryLink);
  };

  useEffect(() => {
    const region = isVenue ? venue?.regions?.[0] : vendor?.regions?.[0];
    const category = isVenue ? venue?.categories : vendor?.categories;

    if (slug && region && category) {
      const searchDataUpdate = (items: SearchResult) => {
        setSearchResults(items);
        setSearchedSlug(slug);
      };

      const search = {
        dataType: venue
          ? BlaceV2Type.InquiryTypes.Venue
          : BlaceV2Type.InquiryTypes.Vendor,
        region,
        category,
      };

      const dataForRequest = {
        searchedSlug,
        slug,
        search,
        searchDataUpdate,
      };

      //@ts-ignore
      SimilarSearchLogic.getSimilarSearchItems(dataForRequest);
    }
  }, [slug, searchedSlug, venue, isVenue, vendor]);

  const sendInquiryDialogClose = useCallback(() => {
    setDialogOpen(false);
    setSelectedVendor(undefined);
    setSelectedVenue(undefined);
    setSelectedCatalogId(undefined);
    setSelectedSearchId(undefined);
  }, []);

  const setFullCardInfo = useCallback(
    async (dataType: string, slug: string | undefined) => {
      if (dataType === BlaceV2Type.InquiryTypes.Venue && slug) {
        const venueResponse =
          await BlaceV2API.SearchServiceV2.getSlugWithV1PayloadForVenue(slug);
        setSelectedVenue(venueResponse.body?.payload);
        setSelectedCatalogId(venueResponse.body?.payload?.catalogId);
        setSelectedCatalogId(venueResponse.body?.payload?.searchId);
      }
      if (dataType === BlaceV2Type.InquiryTypes.Vendor && slug) {
        const vendorResponse =
          await BlaceV2API.SearchServiceV2.getSlugWithV1PayloadForVendor(slug);
        const vendorData = vendorResponse.body?.payload;
        setSelectedVendor(vendorData);
        setSelectedCatalogId(vendorResponse.body?.payload?.catalogId);
        setSelectedSearchId(vendorResponse.body?.payload?.searchId);
      }

      setDialogOpen(true);
    },
    []
  );

  const sendInquiryDialogOpen = useCallback(
    (dataType: string, slug?: string) => {
      setFullCardInfo(dataType, slug);
    },
    [setFullCardInfo]
  );

  const commonSearch = useMemo(() => {
    if (inquiryType && searchResults && (venuesList || vendorsList)) {
      if (isVenue) {
        return [
          { type: BlaceV2Type.InquiryTypes.Venue, itemsList: searchResults },
          { type: BlaceV2Type.InquiryTypes.Vendor, itemsList: vendorsList },
        ];
      }
      return [
        { type: BlaceV2Type.InquiryTypes.Vendor, itemsList: searchResults },
        { type: BlaceV2Type.InquiryTypes.Venue, itemsList: venuesList },
      ];
    }
  }, [inquiryType, searchResults, venuesList, vendorsList, isVenue]);

  if (!commonSearch) {
    return <BaseLoader wrapperClassName={styles.progressWrapper} />;
  }

  return (
    <div className={styles.container}>
      <ToastMessage severity="success">
        <ToastInfo
          handleOnClick={handleOnClick}
          inquiryType={inquiryType}
          showButton={true}
        />
      </ToastMessage>
      <VendorVenueSection
        inquiryId={inquiryId}
        commonSearch={commonSearch}
        sendIquiryHandler={sendInquiryDialogOpen}
        backgroundImageCity={backgroundImageCity}
      />
      <SendInquiryDialog
        dialogOpen={dialogOpen}
        inquiryFormData={inquiryFormData}
        selectedVenue={selectedVenue}
        selectedVendor={selectedVendor}
        selectedCatalogId={selectedCatalogId}
        selectedSearchId={selectedSearchId}
        sendInquiryDialogClose={sendInquiryDialogClose}
        setInquiryLimitReached={setInquiryLimitReached}
      />
      <ReachedLimitPopup
        isLimitReachedPopupVisible={isLimitReachedPopupVisible}
        handleDialogClose={handleDialogClose}
      />
    </div>
  );
}

export default InquiryComplete;
