import { Divider, Typography } from "@mui/material";
import cn from "classnames";
import BellIcon from "@/public/icons/bellIcon.svg";
import { BaseButton } from "@/src/component/base";
import { IconBlock } from "@/src/component/view/Dashboard/component/IconBlock";
import { ProgressBar } from "@/src/component/view/Dashboard/component/ProgressBar";
import { DateHelper } from "@/src/util/helper";
import styles from "./PaymentSummary.module.scss";

interface Payment {
  total: number;
  paid: number;
  outstanding: number;
  dueDate: string;
  next: string;
  isCanceled: boolean;
  totalPayments: number;
  paymentsCompleted: number;
}
interface PaymentSummaryProps {
  payment: Payment;
}

function PaymentSummary({ payment }: PaymentSummaryProps) {
  const DAYS_THRESHOLD = 5;
  const daysToDueDate = DateHelper.getDateDifferenceInDays(
    new Date(),
    new Date(payment.dueDate)
  );
  const isDueDate = daysToDueDate <= DAYS_THRESHOLD && daysToDueDate >= 0;
  const isOverdue = daysToDueDate < 0;

  const paymentSummaryContainerClasses = cn(styles.paymentSummaryContainer, {
    [styles.canceled]: payment.isCanceled,
    [styles.isOverdue]: isOverdue,
  });

  const payButtonClasses = cn(styles.payInvoiceButton, {
    [styles.overdue]: isOverdue,
  });

  const paymentSummaryTextClasses = cn(styles.paymentSummaryText, {
    [styles.zeroPayment]: payment?.outstanding === 0,
  });

  return (
    <>
      <div className={paymentSummaryContainerClasses}>
        <Typography variant="subtitle1" className={styles.paymentSummaryTitle}>
          PAYMENTS
        </Typography>
        <ProgressBar totalPayments={5} paymentsCompleted={1} />
        <div className={styles.paymentSummaryProgressContainer}>
          <Typography variant="h4" className={styles.paymentSummaryProgress}>
            <span>{`${payment.paymentsCompleted} `}</span>
            {`/ ${payment.totalPayments}`}
          </Typography>
          {(isOverdue || isDueDate) && (
            <BaseButton className={payButtonClasses}>Pay invoice</BaseButton>
          )}
        </div>
        <Divider className={styles.paymentSummaryDivider} />
        <div className={styles.paymentSummary}>
          <div>
            <Typography
              variant="subtitle1"
              className={styles.paymentSummarySubTitle}
            >
              TOTAL
            </Typography>
            <Typography
              variant="subtitle1"
              className={styles.paymentSummaryText}
            >
              ${payment?.total?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              className={styles.paymentSummarySubTitle}
            >
              PAID
            </Typography>
            <Typography
              variant="subtitle1"
              className={styles.paymentSummaryText}
            >
              ${payment?.paid?.toLocaleString()}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle1"
              className={styles.paymentSummarySubTitle}
            >
              OUTSTANDING
            </Typography>
            <Typography
              variant="subtitle1"
              className={paymentSummaryTextClasses}
            >
              ${payment?.outstanding?.toLocaleString()}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.nextPaymentInfo}>
        <IconBlock
          icon={BellIcon}
        >{`Next payment $${payment?.next} due ${payment?.dueDate}`}</IconBlock>
      </div>
      <Divider />
    </>
  );
}

export default PaymentSummary;
