import type { ReactElement } from "react";
import { NumberHelper } from "@/src/util";
import { DetailSectionInfoBody } from "@src/component/partial";
import styles from "./VenueCapacity.module.scss";

interface VenueCapacityItemProps {
  Icon: ReactElement;
  name: string;
  capacity?: number;
}

function VenueCapacityItem({ Icon, name, capacity }: VenueCapacityItemProps) {
  if (!capacity) {
    return <></>;
  }

  return (
    <div className={styles.capacityGridItem}>
      <div className={styles.capacityGridItemLeft}>
        <div className={styles.capacityGridItemIcon}>{Icon}</div>
        <DetailSectionInfoBody
          tag="body1"
          className={styles.capacityGridItemCapacity}
        >
          {name} - up to <span>{NumberHelper.withCommas(`${capacity}`)}</span>
        </DetailSectionInfoBody>
      </div>
    </div>
  );
}

export default VenueCapacityItem;
