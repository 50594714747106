import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { BaseButton } from "@/src/component/base";
import { NoSSR } from "@/src/component/partial";
import { APP_TENANT, DEBUG_DEV_MODE, ENV, KEYS } from "@/src/const";
import { ActivityLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { StorageHelper } from "@/src/util";
import styles from "./Debug.module.scss";

const storageHelperDebug = StorageHelper();

function Debug() {
  const [location, setLocation] = useState<
    BlaceV2Type.ActivityGeoLocation | undefined
  >(undefined);
  const [sessionId, setSessionId] = useState<String | undefined>(undefined);
  const [persistentId, setPersistentId] = useState<String | undefined>(
    undefined
  );
  const [windowDims, setWindowDims] = useState<{ w?: number; h?: number }>({});
  const [debugLogging, setDebugLogging] =
    storageHelperDebug.useLocalStorage<boolean>(
      KEYS.DEBUG_DEV_MODE,
      DEBUG_DEV_MODE
    );

  useEffect(() => {
    (async () => {
      try {
        setLocation(await ActivityLogic.getGeoLocation());
        setSessionId(await ActivityLogic.SESSION_ID());
        setPersistentId(await ActivityLogic.PERSISTENT_ID());
        setWindowDims({ w: window.innerWidth, h: window.innerHeight });
      } catch (error) {
        /**this doesn't matter */
      }
    })();
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDims({ w: window.innerWidth, h: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <NoSSR>
      <div className={styles.debug}>
        <Typography variant="h1">DEBUG</Typography>
        <div className={styles.debugOptions}>
          <div className={styles.debugOptionRow}>
            <Typography variant="h4">Console Logging:</Typography>
            <BaseButton
              variant={`${debugLogging}` === "true" ? "contained" : "outlined"}
              size="small"
              className={""}
              onClick={() => {
                setDebugLogging(!debugLogging);
              }}
              suppressHydrationWarning={true}
            >
              {`${debugLogging}` === "true" ? "Turn Off" : "Turn On"}
            </BaseButton>
          </div>
          {location && (
            <>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Estimated Latitude:</Typography>
                <Typography variant="h5">{location.latitude}</Typography>
              </div>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Estimated Longitude:</Typography>
                <Typography variant="h5">{location.longitude}</Typography>
              </div>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Estimated City:</Typography>
                <Typography variant="h5">{location.city}</Typography>
              </div>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Estimated State/Region:</Typography>
                <Typography variant="h5">{location.state}</Typography>
              </div>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Estimated Country:</Typography>
                <Typography variant="h5">{location.country}</Typography>
              </div>
            </>
          )}
          {windowDims?.w && (
            <>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Window Width:</Typography>
                <Typography variant="h5">{windowDims.w}px</Typography>
              </div>
              <div className={styles.debugOptionRow}>
                <Typography variant="h4">Window Height:</Typography>
                <Typography variant="h5">{windowDims.h}px</Typography>
              </div>
            </>
          )}
          <div className={styles.debugOptionRow}>
            <Typography variant="h4">Session ID:</Typography>
            <Typography variant="h5">{sessionId}</Typography>
          </div>
          <div className={styles.debugOptionRow}>
            <Typography variant="h4">Persistent ID:</Typography>
            <Typography variant="h5">{persistentId}</Typography>
          </div>
          <div className={styles.debugOptionRow}>
            <Typography variant="h4">All Env ID:</Typography>
            <Typography variant="h5">{APP_TENANT}</Typography>
          </div>
          <div className={styles.debugOptionRow}>
            <Typography variant="h4">Environment:</Typography>
            <Typography variant="h5">{ENV}</Typography>
          </div>
        </div>
      </div>
    </NoSSR>
  );
}

export default Debug;
