import { Typography } from "@mui/material";
import Image from "next/image";
import HomeIcon from "@/public/icons/HomeIcon.svg";
import SubtractIcon from "@/public/icons/SubtractIcon.svg";
import { ImgBgResponsive } from "@/src/component/base";
import { ASSET_URL } from "@/src/const";
import { ImageFromCDN } from "@/src/util";
import styles from "./SimilarSection.module.scss";

interface SimilarSectionProps {
  inquiryType?: string;
  titleText: string;
  descriptionText: string;
  backgroundImageCity?: string;
}

function SimilarSection({
  inquiryType,
  titleText,
  descriptionText,
  backgroundImageCity,
}: SimilarSectionProps) {
  return (
    <ImgBgResponsive
      className={styles.headerBgImageWrapper}
      imageUrl={
        backgroundImageCity
          ? ImageFromCDN.imageSizeAndQuality(
              `/${backgroundImageCity}_bg_1.png`,
              80,
              900,
              true
            )
          : `${ASSET_URL}/cityBg.jpg?format=auto`
      }
      lazy={"eager"}
    >
      <div className={styles.similarWrapper}>
        <Typography variant="h3" className={styles.similarHeading}>
          <Image
            src={inquiryType === "venue" ? HomeIcon : SubtractIcon}
            alt="similar icon"
            className={
              inquiryType === "venue"
                ? styles.similarVenueIcon
                : styles.similarVendorIcon
            }
          />
          {titleText}
        </Typography>
        <Typography className={styles.textRecommend}>
          {descriptionText}
        </Typography>
      </div>
    </ImgBgResponsive>
  );
}

export default SimilarSection;
