import React from "react";
import { Box, CircularProgress } from "@mui/material";
import styles from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatTabFallback/ChatTabFallback.module.scss";

interface FetchingProps {
  sizeValue?: number;
}

function Fetching({sizeValue = 60}:FetchingProps) {
  return (
    <Box className={styles.loadingSpinnerWrapper}>
      <CircularProgress size={sizeValue} color="secondary" />
    </Box>
  );
}

export default Fetching;
