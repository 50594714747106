export function ArrowRightSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.80496 3.52832L8.86215 4.47113L11.7241 7.33306H3.33356C2.96537 7.33306 2.66689 7.63153 2.66689 7.99972C2.66689 8.36791 2.96537 8.66639 3.33356 8.66639H11.7241L8.86215 11.5283L9.80496 12.4711L14.2764 7.99972L9.80496 3.52832Z"
        fill="#455064"
      />
    </svg>
  );
}
