import { Dispatch, memo, SetStateAction, useMemo } from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { Event } from "@/src/component/view/Dashboard/component/LeftContainer/components/Event";
import { SearchFiltersFallback } from "@/src/component/view/Dashboard/component/LeftContainer/components/SearchFiltersFallback";
import {
  InquiryFiltersType,
  InquirySearchType,
} from "@/src/type/blaceV2/BlaceV2Type";
import styles from "./EventList.module.scss";

interface EventListProps {
  isNoSearchMatchesInquiry: boolean;
  isNoFiltersMatchesInquiry: boolean;
  isErrorBySearch: boolean;
  isErrorByFiltering: boolean;
  inquiriesList?: InquirySearchType[] | [];
  selectedInquiryId?: string;
  isPaginationHidden?: boolean;
  setIsFetching: Dispatch<SetStateAction<boolean>>;
  onInquiryItemClick: (id: string) => void;
  setSearchValue: (value: string) => void;
  setAppliedFilters: Dispatch<SetStateAction<InquiryFiltersType | undefined>>;
  setIsURLWithQueryParams: Dispatch<SetStateAction<boolean>>;
}

function EventList({
  isNoSearchMatchesInquiry,
  isNoFiltersMatchesInquiry,
  isErrorBySearch,
  isErrorByFiltering,
  inquiriesList,
  selectedInquiryId,
  isPaginationHidden,
  onInquiryItemClick,
  setIsFetching,
  setSearchValue,
  setAppliedFilters,
  setIsURLWithQueryParams,
}: EventListProps) {
  const isFallback =
    isNoSearchMatchesInquiry ||
    isNoFiltersMatchesInquiry ||
    isErrorBySearch ||
    isErrorByFiltering;

  const eventListClasses = useMemo(() => {
    return isPaginationHidden
      ? [styles.eventList, styles.fullHeight]
      : [styles.eventList];
  }, [isPaginationHidden]);

  if (isFallback) {
    return (
      <SearchFiltersFallback
        isNoSearchMatchesInquiry={isNoSearchMatchesInquiry}
        isNoFiltersMatchesInquiry={isNoFiltersMatchesInquiry}
        isErrorBySearch={isErrorBySearch}
        isErrorByFiltering={isErrorByFiltering}
        setIsFetching={setIsFetching}
        setSearchValue={setSearchValue}
        setAppliedFilters={setAppliedFilters}
        setIsURLWithQueryParams={setIsURLWithQueryParams}
      />
    );
  }

  return (
    <Box className={cn(eventListClasses)}>
      {inquiriesList?.map((inquiry: InquirySearchType) => {
        return (
          <Event
            key={inquiry.inquiryId}
            inquiry={inquiry}
            selectedInquiryId={selectedInquiryId}
            onInquiryItemClick={onInquiryItemClick}
          />
        );
      })}
    </Box>
  );
}

export default memo(EventList);
