import { useEffect, useState } from "react";
import { SplitLayout } from "@/src/component/layout";
import { PageLoading } from "@/src/component/partial";
import { useNextNavigation } from "@/src/hook";
import { AuthLogic } from "@/src/model";
import { BlaceV1API } from "@/src/service";
import { Log, URLHelper } from "@/src/util";

//import styles from "./AuthCallback.module.scss";

function AuthCallback() {
  const [hasError, setHasError] = useState<string | undefined>(undefined);
  const [started, setStarted] = useState<boolean>(false);
  const nextNavigation = useNextNavigation();

  useEffect(() => {
    if (started) {
      return;
    }
    //make sure this page runs only once
    setStarted(true);

    (async () => {
      const hash = URLHelper.urlGetParameter("hash");
      if (!hash || (hash ?? "").length < 10) {
        //TODO: hash missing
        setHasError("The secure token is invalid to complete authentication");
        return;
      }

      const response = await BlaceV1API.AuthService.getMagicLink(hash);
      const token = response.body?.data?.token;
      const currentUser = response.body?.data;

      if (response.success && typeof token === "string" && currentUser) {
        await AuthLogic.storeAuthToken(token, currentUser);
        const { pathname } = window.location;
        const callbackPath = pathname.includes("password-change")
          ? "/auth/reset-password"
          : await AuthLogic.getAuthCallbackPath(currentUser);

        Log.logToConsoleDebug(
          "AuthCallback.ts",
          "will redirect client after auth",
          [{ callbackPath }]
        );

        await AuthLogic.performRedirectAfterAuth(
            currentUser,
            token,
            callbackPath,
            nextNavigation.router
        );

        return;
      }

      //TODO: handle the error message
      setHasError("An error was encountered while completed authentication");
    })();
  }, [nextNavigation.router, started]);

  return (
    <SplitLayout>
      {!hasError && <PageLoading />}
      {hasError && <div>{hasError}</div>}
    </SplitLayout>
  );
}

export default AuthCallback;
