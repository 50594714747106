import type { ReactElement } from "react";
import AccessibleOutlinedIcon from "@mui/icons-material/AccessibleOutlined";
import AirTwoToneIcon from "@mui/icons-material/AirTwoTone";
import ChairAltTwoToneIcon from "@mui/icons-material/ChairAltTwoTone";
import DeckTwoToneIcon from "@mui/icons-material/DeckTwoTone";
import DomainTwoToneIcon from "@mui/icons-material/DomainTwoTone";
import DoorFrontTwoToneIcon from "@mui/icons-material/DoorFrontTwoTone";
import FilterHdrTwoToneIcon from "@mui/icons-material/FilterHdrTwoTone";
import FullscreenTwoToneIcon from "@mui/icons-material/FullscreenTwoTone";
import KitchenTwoToneIcon from "@mui/icons-material/KitchenTwoTone";
import SettingsOverscanTwoToneIcon from "@mui/icons-material/SettingsOverscanTwoTone";
import SpeakerTwoToneIcon from "@mui/icons-material/SpeakerTwoTone";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import VerticalAlignTopTwoToneIcon from "@mui/icons-material/VerticalAlignTopTwoTone";
import WcTwoToneIcon from "@mui/icons-material/WcTwoTone";
import WifiTwoToneIcon from "@mui/icons-material/WifiTwoTone";
import {
  DetailSectionInfo,
  DetailSectionInfoBodyIcon,
} from "@/src/component/partial";
import { VenueLogic } from "@/src/model";
import { StrapiCMSType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import { StringHelper } from "@/src/util";
import styles from "./VenueAmenities.module.scss";

interface VenueAmenitiesProps {
  venueAttributes?: SearchType.SearchAttribute[];
}

const AMENITIES: Record<StrapiCMSType.Data.VenueAmenity, string> = {
  A_V_CAPABILITIES: "A/V Capabilities",
  ADA_ACCESSIBLE: "ADA Accessible",
  BACK_OF_HOUSE_SPACE: "Back of House Space",
  COLUMN_FREE: "Column-free",
  ELEVATOR_ACCESS: "Elevator Access",
  FURNISHED: "Furnished",
  GENDER_NEUTRAL_RESTROOMS: "Gender Neutral Restrooms",
  HIGH_CEILING: "High Ceilings",
  HVAC: "HVAC",
  KITCHEN: "Kitchen",
  OUTDOOR_SPACE: "Outdoor Space",
  ROOFTOP: "Rooftop",
  STOREFRONT: "Storefront",
  VIEWS: "Views",
  WIFI: "WIFI",
};

const AMENITIES_ICONS: Record<StrapiCMSType.Data.VenueAmenity, ReactElement> = {
  A_V_CAPABILITIES: <SpeakerTwoToneIcon color="inherit" />,
  ADA_ACCESSIBLE: <AccessibleOutlinedIcon color="inherit" />,
  BACK_OF_HOUSE_SPACE: <DoorFrontTwoToneIcon color="inherit" />,
  COLUMN_FREE: <SettingsOverscanTwoToneIcon color="inherit" />,
  ELEVATOR_ACCESS: <DomainTwoToneIcon color="inherit" />,
  FURNISHED: <ChairAltTwoToneIcon color="inherit" />,
  GENDER_NEUTRAL_RESTROOMS: <WcTwoToneIcon color="inherit" />,
  HIGH_CEILING: <VerticalAlignTopTwoToneIcon color="inherit" />,
  HVAC: <AirTwoToneIcon color="inherit" />,
  KITCHEN: <KitchenTwoToneIcon color="inherit" />,
  OUTDOOR_SPACE: <DeckTwoToneIcon color="inherit" />,
  ROOFTOP: <FilterHdrTwoToneIcon color="inherit" />,
  STOREFRONT: <StorefrontTwoToneIcon color="inherit" />,
  VIEWS: <FullscreenTwoToneIcon color="inherit" />,
  WIFI: <WifiTwoToneIcon color="inherit" />,
};

function VenueAmenities({ venueAttributes }: VenueAmenitiesProps) {
  const amenities = VenueLogic.getAttributesByType("amenities", venueAttributes);
  if (!amenities) {
    return <></>;
  }

  return (
    <DetailSectionInfo
      title="Amenities"
      Component={
        <div className={styles.venueLabelContainer}>
          {Object.keys(AMENITIES).map((key: any) => {
            const AMENITIES_KEY = key as StrapiCMSType.Data.VenueAmenity;
            const strikeThrough: boolean = !StringHelper.hasData(
              amenities[AMENITIES[AMENITIES_KEY]]?.label
            );
            return (
              <DetailSectionInfoBodyIcon
                key={AMENITIES_KEY}
                Icon={AMENITIES_ICONS[AMENITIES_KEY]}
                tag={strikeThrough ? "body1" : "h4"}
                strikeThrough={strikeThrough}
              >
                {AMENITIES[AMENITIES_KEY]}
              </DetailSectionInfoBodyIcon>
            );
          })}
        </div>
      }
    />
  );
}

export default VenueAmenities;
