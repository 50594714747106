import { ReactElement } from "react";
import { BaseButton } from "@/src/component/base";
import styles from "./ListingCardWrapper.module.scss";

interface ListingCardWrapperProp {
  children: ReactElement;
  card: ReactElement;
  sendIquiryHandler: (dataType: string, slug: string) => void;
}

function ListingCardWrapper({
  children,
  card,
  sendIquiryHandler,
}: ListingCardWrapperProp) {
  const onClickHandler = () => {
    sendIquiryHandler(card.props.item.dataType, card.props.item.slug);
  };

  return (
    <>
      {children}
      <div className={styles.sendInquiryWrapper}>
        <BaseButton
          variant="contained"
          fullWidth
          onClick={onClickHandler}
          aria-label="Inquiry Complete Button"
        >
          Contact {card.props.item.title ?? "Us"}
        </BaseButton>
      </div>
    </>
  );
}

export default ListingCardWrapper;
