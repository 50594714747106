import { InquiryStatusLabels } from "@/src/type/blaceV2";
import styles from "./InquiryStatusLabel.module.scss";

interface InquiryStatusLabelProps {
  inquiryStatus: InquiryStatusLabels;
}

function InquiryStatusLabel({ inquiryStatus }: InquiryStatusLabelProps) {
  const renderInquiryStatusLabel = (inquiryStatus: InquiryStatusLabels) => {
    switch (inquiryStatus) {
      case InquiryStatusLabels.Сompleted:
        return (
          <div className={styles.completed}>
            {InquiryStatusLabels.Сompleted}
          </div>
        );
      case InquiryStatusLabels.Booked:
        return (
          <div className={styles.booked}>
            {InquiryStatusLabels.Booked}
          </div>
        );
      case InquiryStatusLabels.Closed:
        return (
          <div className={styles.closed}>{InquiryStatusLabels.Closed}</div>
        );
      case InquiryStatusLabels.Booked:
        return (
          <div className={styles.completed}>{InquiryStatusLabels.Booked}</div>
        );
      default:
        return <div className={styles.open}>{InquiryStatusLabels.Open}</div>;
    }
  };

  return <>{renderInquiryStatusLabel(inquiryStatus)}</>;
}

export default InquiryStatusLabel;
