import { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Divider, Typography } from "@mui/material";
import Link from "next/link";
import { BaseButton } from "@/src/component/base";
import { SplitLayout } from "@/src/component/layout";
import {
  GeneralInquiryForm,
  MailTo,
  SplitLayoutFormContainer,
} from "@/src/component/partial";
import { useCMS } from "@/src/component/provider";
import styles from "./GeneralInquiry.module.scss";

function GeneralInquiry() {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const cms = useCMS();

  if (isSubmitted) {
    return (
      <SplitLayout>
        <SplitLayoutFormContainer
          title={"WE ARE READY TO HELP"}
          message={
            "Your question has been received.  A member of our team will reach out as soon as possible"
          }
          returnTo={{ hide: true }}
        >
          <Link
            href={cms.constructLink("/discover")}
            aria-label="Go to Home"
          >
            <BaseButton
              type="button"
              variant="contained"
              size="large"
              fullWidth
            >
              Return Home
            </BaseButton>
          </Link>
        </SplitLayoutFormContainer>
      </SplitLayout>
    );
  }

  return (
    <SplitLayout>
      <SplitLayoutFormContainer
        title={"HOW CAN WE HELP?"}
        message={
          "The BLACE team is here to help.   Please complete the form below and we will be in touch as soon as we can."
        }
        returnTo={{ hide: true }}
      >
        <GeneralInquiryForm onSuccess={() => setIsSubmitted(true)} />
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <MailTo
          href="info@blace.com"
          subject="General Help"
          action="GeneralInquiryContactUs"
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.bttnInner}>
              <MailOutlineIcon />
              <span>info@blace.com</span>
            </div>
          </BaseButton>
        </MailTo>
        <MailTo
          href="press@blace.com"
          subject="Press"
          action="PressGeneralInquiryContactUs"
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            <div className={styles.bttnInner}>
              <MailOutlineIcon />
              <span>press@blace.com</span>
            </div>
          </BaseButton>
        </MailTo>
        <Divider>
          <Typography variant="subtitle2">OR</Typography>
        </Divider>
        <Link
          href={cms.constructLink("/discover")}
          aria-label="Go to Home"
        >
          <BaseButton
            type="button"
            variant="outlined"
            size="large"
            color="primary"
            fullWidth
          >
            RETURN HOME
          </BaseButton>
        </Link>
        <div className={styles.marginBottom} />
      </SplitLayoutFormContainer>
    </SplitLayout>
  );
}

export default GeneralInquiry;
