import React from "react";
import { Box } from "@mui/material";
import { LogListItemManager } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/LogListItemManager";
import { InquiryLog, InquiryMessagesList } from "@/src/type/blaceV2";
import { DateHelper } from "@/src/util/helper";
import styles from "./LogListItem.module.scss";
interface LogListItemProps {
  inquiryMessages: InquiryMessagesList;
}

function LogListItem({ inquiryMessages }: LogListItemProps) {
  const messagesWithSameDate: InquiryLog[] =
    Object.values(inquiryMessages).flat();

  return (
    <ul className={styles.loglist}>
      {!!messagesWithSameDate.length && (
        <Box className={styles.logListDateWrapper}>
          <Box className={styles.logListDate}>
            {DateHelper.formatRelativeDateForHistory(
              messagesWithSameDate[0].createDate
            )}
          </Box>
        </Box>
      )}
      {messagesWithSameDate?.map((inquiryMessage, index) => {
        return (
          <li key={index} className={styles.listItem}>
            <LogListItemManager inquiryMessage={inquiryMessage} />
          </li>
        );
      })}
    </ul>
  );
}

export default LogListItem;
