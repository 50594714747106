import { ReactElement } from "react";
import { Typography } from "@mui/material";
import styles from "./VenueQuickFacts.module.scss";

interface VenueQuickFactsItemProps {
  fact: string;
  Icon: ReactElement;
}

function VenueQuickFactsItem({ fact, Icon }: VenueQuickFactsItemProps) {
  return (
    <div className={styles.venueQuickFactsItem}>
      {Icon}
      <Typography variant="body1">{fact}</Typography>
    </div>
  );
}

export default VenueQuickFactsItem;
