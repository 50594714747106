import React from "react";
import { VenueLogic } from "@/src/model";
import { VenueType } from "@/src/type";
import { SearchType } from "@/src/type/blaceV2";
import styles from "./VenueRoom.module.scss";
import VenueRoomItemV1 from "./VenueRoomItemV1";
import VenueRoomItemV2 from "./VenueRoomItemV2";

interface VenueRoomProps {
  venue: SearchType.SearchItem;
}

function VenueRoom({ venue }: VenueRoomProps) {
  const rooms = VenueLogic.getVenueRooms(venue);
  const isNewVersion = VenueLogic.hasVenueV2Rooms(venue);

  if (!rooms.length) {
    return false;
  }

  return <div className={styles.venueRoom}>
    {rooms.map((room) =>
      isNewVersion ? (
        <VenueRoomItemV2
          key={room.id}
          room={room as SearchType.SearchRoom}
        />
      ) : (
        <VenueRoomItemV1 key={room.id} room={room as VenueType.Room} />
      )
    )}
  </div>;
}

export default React.memo(VenueRoom);
