import { useCallback, useEffect, useMemo } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import ToggleMapIcon from "@/public/icons/pinMapToggleIcon.svg";
import { useNextNavigation } from "@/src/hook";
import { ActivityLogic } from "@/src/model";
import { ListingDisplayVariant } from "@/src/type";
import styles from "./MapButton.module.scss";

interface MapButtonProps {
  viewVariant: keyof typeof ListingDisplayVariant;
  handleMapToggle: (viewVariant?: keyof typeof ListingDisplayVariant) => void;
  isCitySelected: boolean;
}

function MapButton({
  viewVariant,
  isCitySelected,
  handleMapToggle,
}: MapButtonProps) {
  const nextNavigation = useNextNavigation();

  /**
   * make the map open / close with url parameters changes
   */
  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    if (!params.get("viewVariant")) {
      if (viewVariant === "map") {
        handleMapToggle("full");
      }
      return;
    }

    if (viewVariant !== params.get("viewVariant")) {
      handleMapToggle();
    }
  }, [viewVariant, nextNavigation.searchParams]);

  const handleFormat = () => {
    ActivityLogic.toActivityService({
      action: "changeMapView",
      actionMessage: viewVariant === "map" ? "closed" : "open",
      locationInApp: "MapButton.ts",
    });
    const toChange = viewVariant === "map" ? "full" : "map";
    nextNavigation.updateQueryString("viewVariant", toChange);
  };

  const currentColorClass = useMemo(() => {
    if (isCitySelected) {
      return viewVariant === "map"
        ? styles.colorSelectedViewVariantMap
        : styles.colorSelectedViewVariantFull;
    }
    return viewVariant === "map"
      ? styles.colorUnselectedViewVariantMap
      : styles.colorUnselectedViewVariantFull;
  }, [viewVariant, isCitySelected]);

  const currentBackgroundClass = useMemo(() => {
    if (viewVariant === "map") {
      return isCitySelected
        ? styles.bgColorSelectedViewVariantMap
        : styles.bgColorUnselectedVariantMap;
    }
    return styles.bgColorViewVariantFull;
  }, [isCitySelected, viewVariant]);

  const iconStyles = useMemo(() => {
    if (viewVariant === "map") {
      return { filter: isCitySelected ? "invert(100%)" : "none" };
    }
    return { filter: isCitySelected ? "none" : "invert(100%)" };
  }, [isCitySelected, viewVariant]);

  const getToggleButtonClasses = useCallback(() => {
    return [
      styles.toggleButtonCommon,
      currentColorClass,
      currentBackgroundClass,
    ];
  }, [currentColorClass, currentBackgroundClass, currentBackgroundClass]);

  return (
    <div className={styles.container}>
      <ToggleButtonGroup
        color="secondary"
        value={viewVariant === "map" ? ["map"] : []}
        onChange={handleFormat}
        aria-label="Toggle Map On / Off"
        size="small"
      >
        <ToggleButton
          value="map"
          aria-label="Map Button"
          className={cn(getToggleButtonClasses())}
        >
          <Image
            src={ToggleMapIcon}
            alt="toggle map card icon"
            style={iconStyles}
          />
          {viewVariant === "full" ? "Show Map" : "Hide Map"}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default MapButton;
