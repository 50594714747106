import { Dispatch, memo, SetStateAction, useMemo } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import styles from "./StatusFilter.module.scss";

export interface StatusFilters {
  open: boolean;
  completed: boolean;
  booked: boolean;
  closed: boolean;
}

interface StatusFilterProps {
  statusFilters: StatusFilters;
  setStatusFilters: Dispatch<SetStateAction<StatusFilters>>;
}

function StatusFilter({ statusFilters, setStatusFilters }: StatusFilterProps) {
  const customLabelStyles = useMemo(() => {
    //todo - create and use theme variables for all colours
    return {
      "& .MuiFormControlLabel-label": {
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        color: "#455064",
      },
    };
  }, []);

  const customCheckboxStyles = useMemo(() => {
    //todo - create and use theme variables for all colours
    return {
      color: "#455064",
      "&.Mui-checked": {
        color: "#205CDF",
      },
    };
  }, []);

  return (
    <>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={statusFilters.open}
              onChange={() => {
                const newStatusFilters = { ...statusFilters };
                newStatusFilters.open = !statusFilters.open;
                setStatusFilters(newStatusFilters);
              }}
            />
          }
          label="Open"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={statusFilters.booked}
              onChange={() => {
                const newStatusFilters = { ...statusFilters };
                newStatusFilters.booked = !statusFilters.booked;
                setStatusFilters(newStatusFilters);
              }}
            />
          }
          label="Booked"
        />
      </FormGroup>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={statusFilters.completed}
              data-testid="checkbox"
              onChange={() => {
                const newStatusFilters = { ...statusFilters };
                newStatusFilters.completed = !statusFilters.completed;
                setStatusFilters(newStatusFilters);
              }}
            />
          }
          label="Completed"
        />
      </FormGroup>
      <FormGroup className={styles.listOptionsItem}>
        <FormControlLabel
          className={styles.listOptionsItemLabel}
          sx={customLabelStyles}
          control={
            <Checkbox
              sx={customCheckboxStyles}
              checked={statusFilters.closed}
              onChange={() => {
                const newStatusFilters = { ...statusFilters };
                newStatusFilters.closed = !statusFilters.closed;
                setStatusFilters(newStatusFilters);
              }}
            />
          }
          label="Closed"
        />
      </FormGroup>
    </>
  );
}

export default memo(StatusFilter);
