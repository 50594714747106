import { Dispatch, SetStateAction } from "react";
import Link from "next/link";
import { BaseButton } from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import styles from "./DashboardMobileManager.module.scss";

interface DashboardMobileManagerProps {
  setIsFAQMobileOpen: Dispatch<SetStateAction<boolean>>;
}

function DashboardMobileManager({ setIsFAQMobileOpen }: DashboardMobileManagerProps) {
  const cms = useCMS();

  const onClickHandler = () => {
    setIsFAQMobileOpen(true);
  };

  return (
    <div className={styles.inquiryManagerList}>
      {false && (
        <BaseButton className={styles.faqButton} onClick={onClickHandler}>
          FAQ
        </BaseButton>
      )}
      <Link href={cms.constructLink("/inquiry/general")}>
        <BaseButton className={styles.faqButton}>Contact Support</BaseButton>
      </Link>
    </div>
  );
}

export default DashboardMobileManager;
