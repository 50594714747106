import { useMemo } from "react";
import { DialogContent, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Image from "next/legacy/image";
import NoEventsIcon from "@/public/icons/noEventsIcon.svg";
import SkeletonDesktop from "@/public/icons/skeletonDesktop.svg";
import SkeletonMobile from "@/public/icons/skeletonMobile.svg";
import { BaseButton } from "@/src/component/base";
import { useBreakPointDown, useNextNavigation } from "@/src/hook";
import { CMSLogic } from "@/src/model";
import styles from "./NoEvents.module.scss";

function NoEvents() {
  const nextNavigation = useNextNavigation();
  const isMobile = useBreakPointDown("xs");
  const dialogStyles = useMemo(() => {
    return isMobile
      ? {
          "& .MuiDialog-container": {
            alignItems: "flex-end",
            marginLeft: "-20px",
            marginRight: "-20px",
          },
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "100%",
            borderRadius: "15px",
            margin: "0 0 -33px",
          },
        }
      : null;
  }, [isMobile]);

  const onClickHandler = () => {
    nextNavigation.router.push(CMSLogic.constructLink("/discover"));
  };

  return (
    <div className={styles.noCreatedIquiriesWrapper}>
      <Image
        src={isMobile ? SkeletonMobile : SkeletonDesktop}
        alt="skeleton"
        layout="fill"
        objectFit="cover"
      />
      <Dialog open={true} className={styles.noInquiryDialog} sx={dialogStyles}>
        <div className={styles.iconWrapper}>
          <Image src={NoEventsIcon} alt="no events icon" />
        </div>
        <DialogContent>
          <Typography variant="h4" className={styles.noInquiryTitle}>
            Oops, it looks like you don’t have any events yet.
          </Typography>
          <Typography className={styles.noInquiryText}>
            Please submit at least one inquiry to view your events.
          </Typography>
          <Divider />
        </DialogContent>
        <BaseButton
          onClick={onClickHandler}
          color="primary"
          className={styles.noInquiryDialogButton}
        >
          Search BLACE
        </BaseButton>
      </Dialog>
    </div>
  );
}

export default NoEvents;
