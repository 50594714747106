import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { invert } from "lodash";
import Image from "next/image";
import { ArrowRightSVG } from "@/src/asset/svg/ArrowRightSVG";
import { INQUIRY_CLOSED_REASON_EXCHANGE } from "@/src/component/view/Dashboard/DashboardConstant";
import { KEYS } from "@/src/const";
import { CloseInquiryOptions } from "@/src/type/blaceV2";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import styles from "./ClosedInquiryBanner.module.scss";

interface ClosedInquiryBannerProps {
  closedReason?: string;
}

function ClosedInquiryBanner({ closedReason }: ClosedInquiryBannerProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  const closedReasonUserValue = useMemo(() => {
    if (!closedReason) {
      return CloseInquiryOptions.OTHER;
    }
    return invert(INQUIRY_CLOSED_REASON_EXCHANGE)[closedReason] ?? CloseInquiryOptions.OTHER;
  }, [closedReason]);

  return (
    <div className={styles.headerContainer}>
      <Image
        src={`${SVG_URL}/errorRingIcon.svg`}
        height="20"
        width="20"
        alt="error status icon"
      />
      <div>
        <Typography className={styles.headerTitle}>
          Inquiry is closed
        </Typography>
        <Typography className={styles.headerReason}>
          Reason: ”{closedReasonUserValue || "The inquiry has been closed"}”
        </Typography>
        {false && (
          <Typography className={styles.headerCTA}>
            Reopen inquiry <ArrowRightSVG />
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ClosedInquiryBanner;
